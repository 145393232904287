import API from "../utilities/LocalApiProxy";

import {
	beginReindexJob,
	beginAuditSearchIndexJob,
	beginAuditAndIndexMissingItemsJob,
} from "./jobProgressActions";

import {
	CREATE_TENANT_DUPLICATE_ID_FAILURE,
	CREATE_TENANT_SUCCESS,
	EDIT_TENANT_FAILURE,
	EDIT_TENANT_SUCCESS,
	ACCESS_SEARCH_AUDIT_PAGE,
	SET_ACTIVE_OPERATION_ON_AUDIT_PAGE,
	OPEN_PERSISTENT_ERROR_SNACKBAR,
	OPEN_GLOBAL_SNACKBAR,
} from "./types";

/*
	Note:  all of the admin/ endpoints are secured via the cognito user being
	in the LexWorkplace_Admin group
*/

export function listTenants() {
	return API.post("/admin/listTenants");
}
export function fetchTenantDetail(tenantID) {
	return API.post("/admin/tenantDetail", { body: { tenantID } });
}
export function fetchUserDetail({ manualTenantID, userIdentifier }) {
	return API.get("/admin/userDetail", {
		queryParams: { manualTenantID, userIdentifier },
	});
}
export function checkIfEmailExistsInCognito(emailAddress) {
	return API.get("/admin/checkIfEmailExistsInCognito", {
		queryParams: { emailAddress },
	});
}
export function checkIfUsernameExistsInCognito(username) {
	return API.get("/admin/checkIfUsernameExistsInCognito", {
		queryParams: { username },
	});
}
export function checkIfTenantExists(manualTenantID) {
	return API.get("/admin/checkIfTenantExists", {
		queryParams: { manualTenantID },
	});
}
export function rebuildSearchIndex(tenantID) {
	return function (dispatch) {
		const successCallback = () => {
			dispatch({
				type: "REINDEX_SUCCESS",
			});
		};
		const failureCallback = () => {
			dispatch({
				type: "REINDEX_FAILURE",
			});
		};
		return API.post("/admin/reindexChildProcess", { body: { tenantID } })
			.then((res) => {
				if (res && res.mysql && res.mysql.jobID) {
					var jobID = res.mysql.jobID;

					beginReindexJob(
						jobID,
						successCallback,
						failureCallback
					)(dispatch);
				} else {
					successCallback();
				}
			})
			.catch((err) => console.log(err));
	};
}
export function getS3BatchJobs(tenantID) {
	return function (dispatch) {
		dispatch({ type: "GET_BATCH_JOBS_REQUEST" });
		return API.post("/admin/getBatchJobs", { body: { tenantID } })
			.then((res) => {
				dispatch({
					type: "GET_BATCH_JOBS_SUCCESS",
					payload: res.mysql,
				});
				return res.mysql;
			})
			.catch(() => {
				dispatch({ type: "GET_BATCH_JOBS_FAILURE" });
			});
	};
}
export function createAndRunBatchJobFromFailures(tenantID, jobID) {
	return API.post("/admin/createAndStartBatchJobFromJobFailureReport", {
		body: { tenantID, jobID },
	});
}

export function accessSearchAuditPage(tenantID) {
	return function (dispatch) {
		dispatch({ type: ACCESS_SEARCH_AUDIT_PAGE, payload: { tenantID } });
	};
}
export function setAuditPageOperation(operation) {
	return function (dispatch) {
		dispatch({
			type: SET_ACTIVE_OPERATION_ON_AUDIT_PAGE,
			payload: { operation },
		});
	};
}

export function auditSearchIndex(tenantID) {
	return function (dispatch) {
		API.post("/admin/audit/checkForMissingItemsInSearchIndex", {
			body: { tenantID },
		}).then((res) => {
			if (res && res.mysql && res.mysql.jobID) {
				var jobID = res.mysql.jobID;

				beginAuditSearchIndexJob(jobID)(dispatch);
			}
		});
	};
}
export function auditAndReIndexMissingItems(tenantID) {
	return function (dispatch) {
		API.post("/admin/audit/reindexMissingItemsInSearchIndex", {
			body: { tenantID },
		}).then((res) => {
			if (res && res.mysql && res.mysql.jobID) {
				var jobID = res.mysql.jobID;

				beginAuditAndIndexMissingItemsJob(jobID)(dispatch);
			}
		});
	};
}
export function resendWelcomeEmail(username) {
	return function (dispatch) {
		dispatch({ type: "_REQUEST" });
		API.post("/admin/user/resendWelcomeEmail", {
			body: { username },
		})
			.then(() => {
				dispatch({ type: "_SUCCESS" });
			})
			.catch(() => {
				console.log("resend welcome email failed");
				dispatch({ type: "_FAILURE" });
			});
	};
}
export function setUserEnabledDisabled({
	newEnabled,
	username,
	sub,
	tenantID,
	successCallback,
}) {
	return function (dispatch) {
		dispatch({ type: "_REQUEST" });
		API.post(
			newEnabled
				? "/admin/user/setUserEnabled"
				: "/admin/user/setUserDisabled",
			{
				body: { username, sub, tenantID },
			}
		)
			.then(() => {
				dispatch({ type: "ENABLE_USER_SUCCESS" });
				successCallback();
			})
			.catch(() => {
				console.log("set user disabled/enabled failed");
				dispatch({ type: "_FAILURE" });
			});
	};
}
export function deleteUser(username, sub, tenantID, successCallback) {
	return function (dispatch) {
		dispatch({ type: "_REQUEST" });
		API.post("/admin/user/delete", {
			body: { username, sub, tenantID },
		})
			.then(() => {
				dispatch({ type: "DELETE_USER_SUCCESS" });
				successCallback();
			})
			.catch(() => {
				console.log("delete user failed");
				dispatch({ type: "_FAILURE" });
			});
	};
}
export function createUser(
	manualTenantID,
	username,
	displayName,
	email,
	adminStatus
) {
	return function () {
		API.post("/admin/user/create", {
			body: {
				manualTenantID,
				email,
				username,
				displayName,
				adminStatus,
			},
		})
			.then((res) => console.log(res))
			.catch((err) => console.log(err));
	};
}
export function adminUpdateUser({ username, emailAddress, tenantID }) {
	return function (dispatch) {
		API.post("/admin/updateUser", {
			body: {
				manualTenantID: tenantID,
				userData: {
					username,
					emailAddress,
				},
			},
		})
			.then((res) => {
				window.globalHistory.push(`/admin/tenant/${tenantID}/users`);
			})
			.catch((err) => {
				dispatch({
					type: OPEN_PERSISTENT_ERROR_SNACKBAR,
					payload: {
						message: "User attributes could not be updated",
					},
				});
			});
	};
}

export function adminSetEmailVerified(username, callback) {
	return function (dispatch) {
		API.post("/admin/setEmailVerified", {
			body: {
				userData: {
					username,
				},
			},
		})
			.then((res) => {
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						message: `User email set to verified.  This user can now recover their password, and login via their email address`,
						variant: "success",
					},
				});
				callback && callback();
			})
			.catch((err) => {
				console.error(err);
				dispatch({
					type: OPEN_PERSISTENT_ERROR_SNACKBAR,
					payload: {
						message: "User email could not be set as verified",
					},
				});
				callback && callback();
			});
	};
}

export function createDatabaseUserRecord(
	manualTenantID,
	cognitoData,
	callback
) {
	return function (dispatch) {
		const {
			cognitoUsername,
			sub,
			cognitoDisplayName,
			cognitoEmailAddress,
		} = cognitoData;
		dispatch({ type: "_REQUEST" });
		API.post("/admin/user/createJustDatabaseUserRecord", {
			body: {
				manualTenantID,
				emailAddress: cognitoEmailAddress,
				username: cognitoUsername,
				sub,
				displayName: cognitoDisplayName,
			},
		})
			.then((res) => {
				dispatch({ type: "_SUCCESS" });
				callback && callback();
			})
			.catch((err) => dispatch({ type: "_FAILURE" }));
	};
}
export function createTenant({
	manualTenantID,
	name,
	regionName,
	customerID,
	usernamePrefix,
	storageLimitGB,
	lxwEdition,
	ocrEnabled,
	ocrPerMonthBaseline,
	literaDocumentCompareEnabled,
	inlineDocumentCompareEnabled,
}) {
	return function (dispatch) {
		dispatch({ type: "_REQUEST" });
		API.post("/admin/createTenant", {
			body: {
				manualTenantID,
				name,
				regionName,
				customerID,
				usernamePrefix,
				storageLimitGB,
				lxwEdition,
				ocrEnabled,
				ocrPerMonthBaseline,
				literaDocumentCompareEnabled,
				inlineDocumentCompareEnabled,
			},
		})
			.then((res) => {
				dispatch({ type: CREATE_TENANT_SUCCESS });
			})
			.catch(({ lxwErrorCode }) => {
				if (lxwErrorCode === 9000) {
					dispatch({ type: CREATE_TENANT_DUPLICATE_ID_FAILURE });
				}
			});
	};
}
export function editTenant({
	manualTenantID,
	name,
	storageLimitGB,
	lxwEdition,
	ocrEnabled,
	ocrPerMonthBaseline,
	literaDocumentCompareEnabled,
	inlineDocumentCompareEnabled,
}) {
	return function (dispatch) {
		API.post("/admin/editTenant", {
			body: {
				manualTenantID,
				name,
				storageLimitGB,
				lxwEdition,
				ocrEnabled,
				ocrPerMonthBaseline,
				literaDocumentCompareEnabled,
				inlineDocumentCompareEnabled,
			},
		})
			.then((res) => {
				dispatch({ type: EDIT_TENANT_SUCCESS });
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: EDIT_TENANT_FAILURE });
			});
	};
}
export function getEverythingForNewTenant() {
	return function (dispatch) {
		API.get("/admin/getNextAvailableFirmID")
			.then((res) => {
				dispatch({
					type: "GET_EVERYTHING_FOR_NEW_TENANT_SUCCESS",
					payload: {
						firmDetails: { id: res.mysql.nextTenantID },
					},
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
export function getEverythingForEditTenant(manualTenantID) {
	return function (dispatch) {
		API.post("/admin/tenantDetail", {
			body: { tenantID: manualTenantID },
		})
			.then((res) => {
				dispatch({
					type: "GET_EVERYTHING_FOR_EDIT_TENANT_SUCCESS",
					payload: {
						firmDetails: res.mysql.tenant,
						usageLimits: res.mysql.usageLimits,
						tenantFeatures: res.mysql.tenantFeatures,
						tenantRegion: res.mysql.tenantRegion,
					},
				});
			})
			.catch((err) => {
				console.error("error getting /admin/tenantDetail");
			});
	};
}
export function unmountNewEditTenant() {
	return function (dispatch) {
		dispatch({
			type: "UNMOUNT_NEW_EDIT_TENANT",
		});
	};
}
export function setUserMFADisabled(username, successCallback) {
	return function (dispatch) {
		dispatch({ type: "_REQUEST" });
		API.post("/admin/user/setUserMFADisabled", {
			body: { username },
		})
			.then(() => {
				dispatch({ type: "DISABLED_USER_MFA_SUCCESS" });
				successCallback();
			})
			.catch(() => {
				console.log("set user MFA disabled/enabled failed");
				dispatch({ type: "_FAILURE" });
			});
	};
}
export function unlockAllDocuments({ sub, tenantID, successCallback }) {
	return function (dispatch) {
		dispatch({ type: "_REQUEST" });
		API.post("/admin/user/unlockAllDocuments", {
			body: { sub, tenantID },
		})
			.then(() => {
				dispatch({ type: "UNLOCK_ALL_DOCUMENTS_SUCCESS" });
				successCallback();
			})
			.catch(() => {
				dispatch({ type: "UNLOCK_ALL_DOCUMENTS_FAILURE" });
			});
	};
}
export function setFirmHidden(manualTenantID, firmIsHidden, successCallback) {
	return function () {
		API.post("/admin/setFirmHidden", {
			body: { manualTenantID, firmIsHidden },
		}).then(() => {
			successCallback();
		});
	};
}
