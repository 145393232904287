import API from "../utilities/LocalApiProxy";

import { openGlobalDialog } from "./globalEditDialogActions";

export function getHomepageData() {
	return function (dispatch) {
		dispatch({ type: "FETCH_HOMEPAGE_REQUEST" });
		API.get("/user/homepage")
			.then((res) => {
				dispatch({
					type: "FETCH_HOMEPAGE_SUCCESS",
					payload: res.mysql,
				});
			})
			.catch((err) => {
				console.error("error getting homepage");
			});
	};
}
export function increaseMonthlyOCR() {
	return function (dispatch) {
		openGlobalDialog(
			{
				label: `Increase OCR Limit`,
				mainText:
					"Allowing a one-time OCR overage will incur additional charges. Would you like to proceed?",
				canSubmit: true,
				hideTextfield: true,
				submitButtonLabel: "Yes - Allow Overage",
				cancelButtonLabel: "No - Cancel",
			},
			() => {
				return API.post("/firm/limits/increaseMonthlyOCR").then(
					(res) => {
						dispatch({
							type: "UPDATE_LIMITS_AND_FEATURES",
							payload: res.mysql,
						});
					}
				);
			},
			(state) => ({ canSubmit: true })
		)(dispatch);
	};
}
