import { SET_DEVELOPER_OVERRIDE } from "../actions/types";

import lxwConstants from "../config/lexworkplace-constants";
const availableDeveloperOverrides = lxwConstants.availableDeveloperOverrides;

import frontendConfig from "../config/legalworks.frontend.config.json";
import buildData from "../buildData/buildData";

const expectedGitBranchForVariant = {
	development: "master",
	newfeature: "newfeature",
	staging: "staging",
	production: "production",
};

const reactAppEnvironment = process.env.REACT_APP_ENVIRONMENT;

const getApiNameOverrideValue = () => {
	const defaultApiName =
		reactAppEnvironment === "local" ? "localstack" : reactAppEnvironment;

	return frontendConfig.apiName !== defaultApiName
		? frontendConfig.apiName
		: "OVERRIDE_DISABLED";
};

const gitBranchDifferentThanVariant = () => {
	const variantName = frontendConfig.variantName;
	const gitBranch = buildData.branch;
	const expectedGitBranch = expectedGitBranchForVariant[variantName];

	// for local development, the branch will always be different.  No point in showing a pill
	if (reactAppEnvironment !== "local") {
		// for a hosted environment, it's possible to configure the newfeature
		// environment to build for any of multiple branches.  If the variantName ('newfeature')
		// doesn't match the branch name, show this in the header
		if (gitBranch !== expectedGitBranch) {
			return gitBranch;
		}
	}
	return "OVERRIDE_DISABLED";
};

const currentLocalstorageDeveloperOverrides = (() => {
	const localString = window.localStorage.getItem("developerOverrides");
	if (localString) {
		return JSON.parse(localString);
	} else {
		return {};
	}
})();

const initialState = {
	bulkDownloadServerAuthority: checkIfValueIsAllowed(
		"bulkDownloadServerAuthority",
		currentLocalstorageDeveloperOverrides.bulkDownloadServerAuthority
	)
		? currentLocalstorageDeveloperOverrides.bulkDownloadServerAuthority
		: "OVERRIDE_DISABLED",
	apiName: getApiNameOverrideValue(),
	gitBranch: gitBranchDifferentThanVariant(),
};

function checkIfValueIsAllowed(key, value) {
	if (availableDeveloperOverrides[key].options) {
		if (
			availableDeveloperOverrides[key].options[value] ||
			value === "OVERRIDE_DISABLED"
		) {
			return true;
		}
	}
	return false;
}

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_DEVELOPER_OVERRIDE: {
			let { key, value } = action.payload;
			let mixin = checkIfValueIsAllowed(key, value)
				? { [key]: value }
				: {};

			const newState = {
				...state,
				...mixin,
			};
			window.localStorage.setItem(
				"developerOverrides",
				JSON.stringify(newState)
			);

			return newState;
		}
		default:
			return state;
	}
}
