import API from "../utilities/LocalApiProxy";
import {
	FETCH_SHARE_DETAILS_REQUEST,
	FETCH_SHARE_DETAILS_SUCCESS,
	FETCH_SHARE_DETAILS_FAILURE,
	FETCH_PUBLIC_SHARE_DETAILS_REQUEST,
	FETCH_PUBLIC_SHARE_DETAILS_SUCCESS,
	FETCH_PUBLIC_SHARE_DETAILS_FAILURE,
	FETCH_SHARE_LIST_REQUEST,
	FETCH_SHARE_LIST_SUCCESS,
	FETCH_SHARE_LIST_FAILURE,
	OPEN_GLOBAL_SNACKBAR,
	GET_SHARE_DOWNLOAD_URL_REQUEST,
	GET_SHARE_DOWNLOAD_URL_SUCCESS,
	GET_SHARE_DOWNLOAD_URL_FAILURE,
	DELETE_EXTERNAL_EMAIL_REQUEST,
	DELETE_EXTERNAL_EMAIL_SUCCESS,
	DELETE_EXTERNAL_EMAIL_FAILURE,
	GET_EXTERNAL_SAVED_EMAIL_ADDRESSES_SUCCESS,
} from "./types";

function showSnackbar(message, variant = "success") {
	return function (dispatch) {
		dispatch({
			type: OPEN_GLOBAL_SNACKBAR,
			payload: {
				message,
				variant: variant,
				loadingSpinnerVisible: false,
			},
		});
	};
}

export function getOutgoingShareList(matterIDFilter) {
	return function (dispatch) {
		dispatch({ type: FETCH_SHARE_LIST_REQUEST });
		API.post("/share/list", {
			body: { matterIDFilter: matterIDFilter ? [matterIDFilter] : null },
		})
			.then((res) => {
				dispatch({
					type: FETCH_SHARE_LIST_SUCCESS,
					payload: { outgoingShareList: res.mysql.outgoingShareList },
				});
			})
			.catch(() => {
				dispatch({
					type: FETCH_SHARE_LIST_FAILURE,
				});
			});
	};
}

export function getShareDetailUser(shareIdentifier) {
	return function (dispatch) {
		dispatch({ type: FETCH_SHARE_DETAILS_REQUEST });
		var queryParams = {};
		if (shareIdentifier.shareID) {
			queryParams.shareID = shareIdentifier.shareID;
		} else if (shareIdentifier.accessHash) {
			queryParams.accessHash = shareIdentifier.accessHash;
		}
		API.get("/share/outgoing/detail", {
			queryParams,
		})
			.then((res) => {
				if (res.mysql.publicShareDetails) {
					dispatch({
						type: FETCH_PUBLIC_SHARE_DETAILS_SUCCESS,
						payload: res.mysql.publicShareDetails,
					});
				} else {
					dispatch({
						type: FETCH_SHARE_DETAILS_SUCCESS,
						payload: {
							shareDetails: res.mysql.shareDetails,
							shareEmails: res.mysql.shareEmails,
						},
					});
				}
			})
			.catch(() => {
				dispatch({ type: FETCH_SHARE_DETAILS_FAILURE });
			});
	};
}

const shareCallbackAction = (shareID, callbackPage, matterIDFilter) => {
	if (callbackPage === "ExternalSharingManagement") {
		return () => getOutgoingShareList(matterIDFilter);
	} else if (callbackPage === "ExternalShareDetail") {
		return () => getShareDetailUser({ shareID });
	} else {
		console.error("no callback action recognised for", { callbackPage });
	}
};

export function revokeShare(shareID, callbackPage, matterIDFilter) {
	var callbackAction = shareCallbackAction(
		shareID,
		callbackPage,
		matterIDFilter
	);

	return function (dispatch) {
		return API.post("/share/outgoing/revoke", { body: { shareID } })
			.then(() => {
				callbackAction()(dispatch);
				showSnackbar("Share revoked")(dispatch);
			})
			.catch(() => {
				callbackAction()(dispatch);
				showSnackbar("Share could not be revoked", "error")(dispatch);
			});
	};
}
export function resendShare(shareID, callbackPage, matterIDFilter) {
	var callbackAction = shareCallbackAction(
		shareID,
		callbackPage,
		matterIDFilter
	);
	return function (dispatch) {
		return API.post("/share/outgoing/resend", { body: { shareID } })
			.then(() => {
				callbackAction()(dispatch);
				showSnackbar("Re-shared and email resent")(dispatch);
			})
			.catch(() => {
				callbackAction()(dispatch);
				showSnackbar("Share could not be resent", "error")(dispatch);
			});
	};
}

export function fetchPublicShareDetails(accessCode, userRegion) {
	return function (dispatch) {
		dispatch({ type: FETCH_PUBLIC_SHARE_DETAILS_REQUEST });
		API.post("/public/share/info", { body: { accessCode, userRegion } })
			.then((results) => {
				dispatch({
					type: FETCH_PUBLIC_SHARE_DETAILS_SUCCESS,
					payload: results.mysql,
				});
			})
			.catch(() => {
				dispatch({
					type: FETCH_PUBLIC_SHARE_DETAILS_FAILURE,
				});
			});
	};
}

export function getSignedUrlForZipDownload(accessCode, userRegion) {
	return function (dispatch) {
		dispatch({ type: GET_SHARE_DOWNLOAD_URL_REQUEST });
		API.post("/public/share/download", { body: { accessCode, userRegion } })
			.then((results) => {
				dispatch({
					type: GET_SHARE_DOWNLOAD_URL_SUCCESS,
					payload: results.mysql,
				});
				window.location.href = results.mysql.url;
			})
			.catch(() => {
				dispatch({
					type: GET_SHARE_DOWNLOAD_URL_FAILURE,
				});
			});
	};
}
export function deleteExternalEmailAddressForUser(emailAddress) {
	return function (dispatch) {
		dispatch({ type: DELETE_EXTERNAL_EMAIL_REQUEST });
		API.post("/user/externalEmail/delete", { body: { emailAddress } })
			.then((res) => {
				dispatch({
					type: DELETE_EXTERNAL_EMAIL_SUCCESS,
				});
				//the new list of saved email addresses is returned after successful delete
				if (res && res.mysql && res.mysql.externalEmailAddresses) {
					dispatch({
						type: GET_EXTERNAL_SAVED_EMAIL_ADDRESSES_SUCCESS,
						payload: res.mysql.externalEmailAddresses,
					});
				}
			})
			.catch(() => {
				dispatch({
					type: DELETE_EXTERNAL_EMAIL_FAILURE,
				});
			});
	};
}
