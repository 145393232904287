import React from "react";
import "./HeaderBar.css";
import logo from "../svg/legalworks-logo.svg";
import PropTypes from "prop-types";
import { DropDownButton } from "./DropDownButton";
import {
	FavoriteDocsDropdown,
	RecentDocsAndMattersDropdown,
} from "./MultiColumnDropdown";
import NewItemDropdown from "./NewItemDropdown";
import WhatsNewDropdown from "./WhatsNewDropdown";

import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { updateWhatsNewDropdownLastHashViewed } from "../actions/browserLocalstorageActions";

import { SearchBox } from "./SearchBox";
import DeveloperOverrides from "./DeveloperOverrides";

const DropdownWithRouter = withRouter(DropDownButton);

class HeaderBar extends React.Component {
	render() {
		return (
			<div className="HeaderBar">
				<div>
					<NavLink to={this.props.isLoggedIn ? "/" : "/login"}>
						<img
							src={logo}
							className="legalworks-logo"
							alt="logo"
						/>
					</NavLink>
				</div>
				<div>
					{this.props.isLoggedIn ? null : (
						<div className="lexTitle">
							<div
								style={{
									lineHeight: "20px",
									fontSize: "20px",
								}}
							>
								LexWorkplace
							</div>
							<div
								style={{
									lineHeight: "12px",
									fontSize: "12px",
								}}
							>
								By Uptime Legal
							</div>
						</div>
					)}
					{this.props.isLoggedIn ? (
						<SearchBox
							placeholder={this.props.searchPlaceholder}
							searchUrlAfterQuery={this.props.searchUrlAfterQuery}
							searchMenuOptions={this.props.searchMenuOptions}
							allMatters={this.props.allMatters}
							allClients={this.props.allClients}
						/>
					) : null}
				</div>
				<div>
					{this.props.isLoggedIn ? (
						<div
							className="headerLeftButtons"
							id="HeaderNewDropdown"
						>
							<NewItemDropdown />
						</div>
					) : null}
					{this.props.isLoggedIn ? (
						<div className="headerLeftButtons">
							<RecentDocsAndMattersDropdown
								title="Recent"
								noIcon
							/>
						</div>
					) : null}
					{this.props.isLoggedIn ? (
						<div className="headerLeftButtons">
							<FavoriteDocsDropdown title="Favorite" noIcon />
						</div>
					) : null}
					{this.props.isLoggedIn ? (
						<div className="headerLeftButtons">
							<WhatsNewDropdown
								whatsNewDropdownLastHashViewed={
									this.props.whatsNewDropdownLastHashViewed
								}
								updateWhatsNewDropdownLastHashViewed={
									this.props
										.updateWhatsNewDropdownLastHashViewed
								}
								whatsNewItems={this.props.whatsNewItems}
								whatsNewItemsHash={this.props.whatsNewItemsHash}
							/>
						</div>
					) : null}
				</div>
				{this.props.isLoggedIn ? (
					<div style={{ marginLeft: "auto" }}>
						<div>
							<DeveloperOverrides />
						</div>
						<div>
							<DropdownWithRouter
								className="IconDropDown HeaderUserDropDown"
								title={this.props.userDisplayName}
								iconName="user"
								menuItems={[
									{
										text: `Region: ${this.props.userRegion}`,
										type: "userRegionFlag",
										region: this.props.userRegion,
									},
									{ text: "Settings", href: "/settings" },
									{ text: "About", href: "/about" },
									{ text: "Log Out", href: "/logout" },
								]}
								pullRight
							/>
						</div>
					</div>
				) : (
					<div className="learnMoreSection">
						<a id="learnMoreLink" href="https://LexWorkplace.com">
							Learn More
						</a>
						<span> about</span>
						<span> LexWorkplace</span>
					</div>
				)}
			</div>
		);
	}
}
HeaderBar.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	userDisplayName: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
	isLoggedIn: state.user.isLoggedIn,
	userDisplayName: state.user.userDisplayName,
	searchPlaceholder: state.globalSearchbar.searchPlaceholder,
	searchUrlAfterQuery: state.globalSearchbar.searchUrlAfterQuery,
	searchMenuOptions: state.globalSearchbar.menuOptions,
	allMatters: state.user.allMatterNames,
	allClients: state.user.allClientNames,
	whatsNewDropdownLastHashViewed:
		state.browserLocalstorage.whatsNewDropdownLastHashViewed,
	whatsNewItems: state.whatsNew.items,
	whatsNewItemsHash: state.whatsNew.itemsHash,
	userRegion: state?.user?.userRegion || "??????",
});
export default connect(mapStateToProps, {
	updateWhatsNewDropdownLastHashViewed,
})(HeaderBar);
