/*
	This is used for client tags, matter tags, matter types, office/branch, document types, document statuses
*/
import {
	LIST_PREFIX_REQUEST,
	LIST_PREFIX_SUCCESS,
	LIST_PREFIX_FAILURE,
	MULTIPLE_FILE_UPLOAD_SUCCESS,
	MULTIPLE_FILE_UPLOAD_FAILURE,
	MULTIPLE_FILE_UPLOAD_CANCELED,
	DOCUMENT_DETAILS_SUCCESS,
	DOCUMENT_DETAILS_FAILURE,
	DOCUMENT_DETAILS_REQUEST,
	UNMOUNT_DOCUMENT_DETAILS,
	SET_SUBHEADER_TEXT,
	UPLOAD_NEW_VERSION_REQUEST,
	UPLOAD_NEW_VERSION_PROGRESS,
	UPLOAD_NEW_VERSION_SUCCESS,
	CREATE_NEW_FOLDER_REQUEST,
	CREATE_NEW_FOLDER_SUCCESS,
	CREATE_NEW_FOLDER_FAILURE,
	GET_NOTE_SUCCESS,
	GET_NOTE_FAILURE,
	UNMOUNT_NOTE_EDITOR,
	UPDATE_NOTE_SUCCESS,
	CREATE_NOTE_SUCCESS,
	ACCESS_CREATE_NOTE,
	SET_DOCUMENT_LOCK_SUCCESS,
	FETCH_RECENT_DOCS_REQUEST_NOGLOBAL,
	FETCH_RECENT_DOCS_SUCCESS_NOGLOBAL,
	FETCH_FAVORITE_DOCS_REQUEST_NOGLOBAL,
	FETCH_FAVORITE_DOCS_SUCCESS_NOGLOBAL,
	UPDATE_DOCUMENT_PROPERTIES_REQUEST,
	UPDATE_DOCUMENT_PROPERTIES_SUCCESS,
	UPDATE_DOCUMENT_PROPERTIES_FAILURE,
	RENAME_REQUEST,
	RENAME_SUCCESS,
	RENAME_FAILURE,
	UPDATE_DOCUMENT_VERSION_COMMENT_REQUEST,
	UPDATE_DOCUMENT_VERSION_COMMENT_SUCCESS,
	UPDATE_DOCUMENT_VERSION_COMMENT_FAILURE,
	OPEN_GLOBAL_SNACKBAR,
	OPEN_PERSISTENT_SNACKBAR,
	DOCUMENT_PREVIEW_CONTENT_SUCCESS,
	DOCUMENT_PREVIEW_CONTENT_FAILURE,
	FETCH_DOCUMENT_PREVIEW_CONTENT,
	MULTI_DOWNLOAD_REQUEST,
	MULTI_DOWNLOAD_SUCCESS,
	MULTI_DOWNLOAD_STARTED,
	MULTI_DOWNLOAD_FAILURE,
	EXTERNAL_SEND_REQUEST,
	EXTERNAL_SEND_SUCCESS,
	EXTERNAL_SEND_STARTED,
	EXTERNAL_SEND_FAILURE,
	GET_EXTERNAL_SAVED_EMAIL_ADDRESSES_SUCCESS,
	BULK_FILE_UPLOAD_FAILURE,
	FILE_UPLOAD_FAILURES,
	RESET_FILE_UPLOAD_FAILURES,
	CLOSE_GLOBAL_SNACKBAR,
	CONVERT_TO_PDF_REQUEST,
	CONVERT_TO_PDF_SUCCESS,
	CONVERT_TO_PDF_STARTED,
	CONVERT_TO_PDF_CANCELED,
	UPDATE_REACT_DIALOG_CONTENT,
	OPEN_REACT_DIALOG,
	CLOSE_REACT_DIALOG,
	DELETE_DOCUMENT_VERSION_REQUEST,
	DELETE_DOCUMENT_VERSION_SUCCESS,
	DELETE_DOCUMENT_VERSION_FAILURE,
} from "./types";

import store from "../store";
import path from "path";

import { openGlobalDialog } from "./globalEditDialogActions";

import { openLauncherLink } from "./launcherActions";

import { deleteMultiselect, restoreMultiselect } from "./multiselectActions";

import {
	beginBulkDownloadJob,
	beginBulkExternalSendJob,
	beginConvertToPDFJob,
} from "./jobProgressActions";

import { beginRenameJob } from "./jobProgressActions";

import { showMatterDetails } from "./matterActions";

import {
	isFileTypeCompatibleWithInline,
	isFileTypeCompatibleWithLitera,
	mapFirmDocsMatterID,
	ocrOnDemandAllowed,
} from "../utilities/utilities";

import axios from "axios";

import API from "../utilities/LocalApiProxy";

import aws_config from "../config/aws_config";
import getBulkUploadFailureMessage from "../components/BulkUploadFailureMessage";

import React from "react";
import { Link } from "react-router-dom";
import { createFilesUploadingContentForDialog } from "../components/reactDialog";

import config from "../config/legalworks.frontend.config";
import { getRecentlyViewMatters } from "./browserLocalstorageActions";

window.axios = axios;
window.aws_config = aws_config;

function chunkArray(arr, len) {
	var chunks = [],
		i = 0,
		n = arr.length;

	while (i < n) {
		chunks.push(arr.slice(i, (i += len)));
	}

	return chunks;
}

function directoryMap(directories) {
	return directories.map((item) => {
		return {
			type: "Folder",
			id: item.id,
			filename: item.name,
			isDeleted: item.isDeleted,
			fullpath: item.path + item.name + "/",
			isFavorite: item.isFavorite,
		};
	});
}
function documentMap(documents, basepath) {
	return documents.map((item) => {
		return {
			type: "File",
			lwid: item.prefix,
			id: item.documentID,
			filename: item.filename,
			isFavorite: item.isFavorite,
			createdBy: item.createdBy,
			filesize: item.filesize,
			lockedBy: item.lockedBy,
			lockedBySub: item.lockedBySub,
			lockStatus: item.lockStatus,
			documentStatus: item.documentStatus,
			documentType: item.documentType,
			createdAt: item.createdAt,
			lastModified: item.lastModified,
			versionNumber: item.versionNumber,
			isDeleted: item.isDeleted,
			size: item.filesize,
			tags: item.tags,
			fullpath: basepath + item.filename,
			isFileTypeCompatibleWithLitera: isFileTypeCompatibleWithLitera(
				item.filename
			),
			isFileTypeCompatibleWithInline: isFileTypeCompatibleWithInline(
				item.filename
			),
			notes: item.notes,
		};
	});
}
function listEmailDirectory(matterID, basepath) {
	return API.get("/email/list", {
		queryParams: { matterID, basepath },
	}).then((result) => {
		var emails = result.mysql.emails.map((item) => {
			return {
				...item,
				type: "Email",
			};
		});
		var directories = directoryMap(result.mysql.directories);
		return Promise.resolve({
			emails,
			directories,
			directoryID: result.mysql.directoryID,
			matter: result.mysql.matter[0],
		});
	});
}

//basepath should end with a /
export function listDirectory(
	matterID,
	basepath,
	history,
	includeMatterDetail
) {
	return function (dispatch) {
		var matterRoot = basepath.split("/")[0];
		dispatch({
			type: LIST_PREFIX_REQUEST,
		});
		if (matterRoot === "email") {
			listEmailDirectory(matterID, basepath)
				.then((res) => {
					dispatch({
						type: LIST_PREFIX_SUCCESS,
						payload: {
							originalPrefix: basepath,
							documents: res.emails.concat(res.directories),
							matterRoot,
							directoryID: res.directoryID,
						},
					});
					includeMatterDetail &&
						showMatterDetails(res.matter)(dispatch);
				})
				.catch(() => {
					dispatch({ type: LIST_PREFIX_FAILURE });
					window.globalHistory.push("/");
				});
		} else if (
			matterRoot === "docs" ||
			matterRoot === "public" ||
			matterRoot === "private"
		) {
			API.get("/document/list", {
				queryParams: { matterID, basepath },
			})
				.then((result) => {
					var files = documentMap(result.mysql.files, basepath);
					var directories = directoryMap(result.mysql.directories);

					dispatch({
						type: LIST_PREFIX_SUCCESS,
						payload: {
							originalPrefix: basepath,
							documents: files.concat(directories),
							matterRoot,
							directoryID: result.mysql.directoryID,
						},
					});

					includeMatterDetail &&
						showMatterDetails(result.mysql.matter[0])(dispatch);
				})
				.catch(() => {
					dispatch({ type: LIST_PREFIX_FAILURE });
					window.globalHistory.push("/");
				});
		} else if (matterRoot === "notes") {
			API.get("/notes/list", {
				queryParams: { matterID, basepath },
			})
				.then((result) => {
					var notes = result.mysql.notes
						.map((n) => ({ ...n, type: "Note" }))
						.concat(directoryMap(result.mysql.directories));
					dispatch({
						type: LIST_PREFIX_SUCCESS,
						payload: {
							originalPrefix: basepath,
							documents: notes,
							matterRoot,
							directoryID: result.mysql.directoryID,
						},
					});
					includeMatterDetail &&
						showMatterDetails(result.mysql.matter[0])(dispatch);
				})
				.catch(() => {
					dispatch({ type: LIST_PREFIX_FAILURE });
					window.globalHistory.push("/");
				});
		}
	};
}

export function getNote(noteID) {
	return function (dispatch) {
		API.get("/notes", { queryParams: { id: noteID } })
			.then((res) => {
				dispatch({
					type: GET_NOTE_SUCCESS,
					payload: {
						note: res.mysql[0],
					},
				});
			})
			.catch(() => {
				window.globalHistory.push("/");
				dispatch({ type: GET_NOTE_FAILURE });
			});
	};
}
export function updateNote(id, noteData, redirectLocation) {
	return function (dispatch) {
		var { name, content } = noteData;
		API.post("/notes/update", { body: { id, name, content } }).then(() => {
			window.globalHistory.push(redirectLocation);
			dispatch({
				type: UPDATE_NOTE_SUCCESS,
			});
		});
	};
}
export function createNote(matterID, directoryID, noteData, redirectLocation) {
	return function (dispatch) {
		const { name, content } = noteData;
		API.put("/notes/create", {
			body: { matterID, directoryID, name, content },
		}).then(() => {
			window.globalHistory.push(redirectLocation);
			dispatch({
				type: CREATE_NOTE_SUCCESS,
			});
		});
	};
}
export function createNoteInRootOfMatter({ matterID, name, content }) {
	return function (dispatch) {
		API.put("/notes/createInRootOfMatter", {
			body: { matterID, name, content },
		})
			.then((res) => {
				console.log(res.mysql);
				const { matterNoteID } = res.mysql;
				const successMessage = (
					<div style={{ textAlign: "left" }}>
						Matter Note created Notes tab of this matter.{" "}
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`https://${config.domain}/notes/${matterNoteID}`}
							style={{
								color: "white",
								textDecoration: "underline",
							}}
							onClick={() => {
								dispatch({
									type: CLOSE_GLOBAL_SNACKBAR,
								});
							}}
						>
							Open Matter Note
						</a>
						.
					</div>
				);
				dispatch({
					type: OPEN_PERSISTENT_SNACKBAR,
					payload: {
						message: successMessage,
						variant: "success",
					},
				});
			})
			.catch((err) => {
				const message = "Matter Note could not be created";
				console.error(message);
				console.error(err);
				dispatch({
					type: OPEN_PERSISTENT_SNACKBAR,
					payload: {
						message,
						variant: "error",
					},
				});
			});
	};
}
export function unmountNoteEditor() {
	return function (dispatch) {
		dispatch({ type: UNMOUNT_NOTE_EDITOR });
	};
}
export function accessCreateNote(matterID, directoryID, fullpath) {
	return function (dispatch) {
		dispatch({
			type: ACCESS_CREATE_NOTE,
			payload: {
				matterID,
				directoryID,
				fullpath,
			},
		});
	};
}
export function getDocumentDetails(documentID) {
	return function (dispatch) {
		dispatch({ type: DOCUMENT_DETAILS_REQUEST });
		dispatch({
			type: SET_SUBHEADER_TEXT,
			payload: {
				subheaderText: "Document Properties",
			},
		});
		API.get("/document", { queryParams: { documentID } })
			.then((res) => {
				dispatch({
					type: DOCUMENT_DETAILS_SUCCESS,
					payload: { ...res.mysql },
				});
			})
			.catch(() => {
				dispatch({
					type: DOCUMENT_DETAILS_FAILURE,
				});
				window.globalHistory.push("/");
			});
	};
}

export function getSignedUrlAndDownload(key, rootS3Folder, versionID) {
	return function (dispatch) {
		var queryParams = {
			key,
			rootS3Folder,
			operation: "getObject",
		};
		if (versionID) {
			queryParams.versionID = versionID;
		}
		API.get("/signed", { queryParams })
			.then((res) => {
				window.location.href = res.mysql.url;
				dispatch({
					type: "GET_SIGNED_URL_SUCCESS",
				});
			})
			.catch((err) => console.log(err));
	};
}

export function getSignedUrlForDocumentAndDownload(documentID, versionID) {
	return function (dispatch) {
		API.get("/signedGetIgnoreLock", {
			queryParams: { documentID, versionID },
		})
			.then((res) => {
				//This downloads the file to the users downloads folder using the filename that is defined by the "response-content-disposition=attachment" property of the header in the pre-signed URL
				window.location.href = res.mysql.url;
				dispatch({
					type: "GET_SIGNED_URL_SUCCESS",
				});
			})
			.catch((err) => console.log(err));
	};
}

// documentID: row.original.id,
// fileName: row.original.filename,
// matterID: this.props.matterID,
// convertAction: "createPDF",
// basepath: this.props.originalPrefix,
export function convertDocumentToPDF({
	sourceDocumentID,
	fileToConvert,
	matterID,
	actionType,
	duplicatePDFDocInfo,
	basepath,
}) {
	return function (dispatch) {
		var newDocumentID;
		const successCallback = (jobData, fn) => {
			var myObject = JSON.parse(jobData.jobData);
			// jobData = {
			// 	"manifestFilename": "7e8491b7c3be86284baa5ccbdbbb63071fad1244.csv",
			// 	"matterID": "85",
			// 	"documentID": 81243,
			// 	"userLocationWhenActionWasTaken": "https://lexworkplace.local:3000/matters/85/docs/a/b/",
			// 	"parentDirectoryPath": "docs/a/b/"
			// }
			newDocumentID = myObject.documentID;

			const successMessage = (
				<div style={{ textAlign: "left" }}>
					Your PDF Conversion is Complete and Available{" "}
					<Link
						style={{ color: "#ffff", textDecoration: "underline" }}
						to={`/docs/${newDocumentID}`}
						onClick={() => {
							dispatch({
								type: CLOSE_GLOBAL_SNACKBAR,
							});
						}}
					>
						Here
					</Link>
					.
				</div>
			);
			if (
				basepath &&
				myObject.userLocationWhenActionWasTaken &&
				myObject.userLocationWhenActionWasTaken === window.location.href
			) {
				listDirectory(myObject.matterID, basepath)(dispatch);
			}
			dispatch({
				type: CONVERT_TO_PDF_SUCCESS,
				payload: {
					message: successMessage,
					loadingSpinnerVisible: false,
					allowClose: true,
					autoHideDuration: null,
				},
			});
		};

		dispatch({ type: CONVERT_TO_PDF_REQUEST });
		API.post("/document/convert", {
			body: {
				sourceDocumentID,
				matterID,
				actionType,
				duplicatePDFDocInfo,
				userLocationWhenActionWasTaken: window.location.href,
				parentDirectoryPath: basepath,
			},
		})
			.then((res) => {
				if (res && res.mysql && res.mysql.jobID) {
					var jobID = res.mysql.jobID;
					beginConvertToPDFJob(
						jobID,
						fileToConvert,
						successCallback
					)(dispatch);
					dispatch({ type: CONVERT_TO_PDF_STARTED });
				}
			})
			.catch(({ lxwErrorCode, lxwErrorData }) => {
				if (lxwErrorCode === 6503) {
					const {
						fileName,
						newDocName,
						//duplicateDocID,
						isDocumentInUse,
					} = lxwErrorData;
					var documentInUseMessage = `"${fileName}" is in use or checked-out by any other user. Can't create New Version of this file.`;
					openGlobalDialog(
						{
							label: `Duplicate File(s) Found`,
							mainText: `There is already a PDF named "${fileName}" Would you like to`,
							canSubmit: true,
							hideTextfield: true,
							type: "docTOPDF",
							readOnly: true,
							hideCancelButton: true,
							extraCSSClass: "WideEditDialog",
							value: {
								newDocName,
								fileName,
								isDocumentInUse,
								documentInUseMessage,
							},
						},
						(state) => {
							if (state.convertToPDFAsNewVersion) {
								return convertDocumentToPDF({
									sourceDocumentID,
									fileToConvert,
									matterID,
									actionType: "createVersion",
									basepath,
								})(dispatch);
							} else {
								return convertDocumentToPDF({
									sourceDocumentID,
									fileToConvert,
									matterID,
									actionType: "createCopy",
									duplicatePDFDocInfo: {
										newUniquePdfName: newDocName,
									},
									basepath,
								})(dispatch);
							}
						},
						(state) => ({ canSubmit: true }),
						() => {
							dispatch({
								type: CONVERT_TO_PDF_CANCELED,
							});
						}
					)(dispatch);
				}
			});
	};
}

export function externalSendMultiple(
	selectedObjects,
	parentDirectoryPath,
	matterID,
	recipients,
	message
) {
	return function (dispatch) {
		const successCallback = () => {
			dispatch({
				type: EXTERNAL_SEND_SUCCESS,
			});
		};
		const failureCallback = () => {
			dispatch({
				type: EXTERNAL_SEND_FAILURE,
			});
		};

		dispatch({ type: EXTERNAL_SEND_REQUEST });

		API.post("/multi/externalSend", {
			body: {
				selectedObjects,
				documentOrEmail: "Document",
				parentDirectoryPath,
				matterID,
				recipients,
				message,
			},
		}).then((res) => {
			if (res && res.mysql && res.mysql.jobID) {
				var jobID = res.mysql.jobID;

				beginBulkExternalSendJob(
					jobID,
					successCallback,
					failureCallback
				)(dispatch);
				dispatch({ type: EXTERNAL_SEND_STARTED });
			}
			if (res && res.mysql && res.mysql.externalEmailAddresses) {
				dispatch({
					type: GET_EXTERNAL_SAVED_EMAIL_ADDRESSES_SUCCESS,
					payload: res.mysql.externalEmailAddresses,
				});
			}
		});
	};
}

export function downloadMultiple(
	selectedObjects,
	documentOrEmail,
	parentDirectoryPath,
	matterID
) {
	return function (dispatch) {
		const successCallback = () => {
			dispatch({
				type: MULTI_DOWNLOAD_SUCCESS,
			});
		};
		const failureCallback = () => {
			dispatch({
				type: MULTI_DOWNLOAD_FAILURE,
			});
		};

		dispatch({ type: MULTI_DOWNLOAD_REQUEST });

		API.post("/multi/download", {
			body: {
				selectedObjects,
				documentOrEmail,
				parentDirectoryPath,
				matterID,
			},
		}).then((res) => {
			if (res && res.mysql && res.mysql.jobID) {
				var jobID = res.mysql.jobID;

				beginBulkDownloadJob(
					jobID,
					false,
					successCallback,
					failureCallback
				)(dispatch);
				dispatch({ type: MULTI_DOWNLOAD_STARTED });
			}
		});
	};
}

export function openDocumentInLauncher(documentID, cognitoSub) {
	return function (dispatch) {
		if (!cognitoSub) {
			console.warn("cognito sub not being passed!");
		}
		const launcherOpenAttempt = openLauncherLink(
			"docs/" + documentID + `?cognitoSub=${cognitoSub}`
		);
		if (launcherOpenAttempt) {
			dispatch({
				type: OPEN_GLOBAL_SNACKBAR,
				payload: {
					message: "Opening Document...",
					loadingSpinnerVisible: true,
					autoHideDuration: 7000,
					allowClose: false,
					disableWindowBlurListener: true,
				},
			});
		} else {
			dispatch({
				type: OPEN_GLOBAL_SNACKBAR,
				payload: {
					message:
						"Could not connect to launcher.  Please see error console for details or contact support",
					variant: "error",
					loadingSpinnerVisible: false,
					allowClose: true,
					disableWindowBlurListener: true,
				},
			});
		}
	};
}
export function createNewFolder(matterID, basepath, dirname) {
	return function (dispatch) {
		dispatch({
			type: CREATE_NEW_FOLDER_REQUEST,
		});
		API.put("/directory", {
			body: { matterID, basepath, dirname },
		})
			.then(() => {
				if (basepath[0] === "/") {
					basepath = basepath.substr(1, basepath.length - 1);
				}
				dispatch({
					type: CREATE_NEW_FOLDER_SUCCESS,
				});
				listDirectory(matterID, basepath)(dispatch);
			})
			.catch(() => {
				dispatch({
					type: CREATE_NEW_FOLDER_FAILURE,
				});
			});
	};
}
export function changeFolderName(matterID, directoryID, dirname, basepath) {
	return function (dispatch) {
		dispatch({ type: RENAME_REQUEST });

		function success() {
			dispatch({ type: RENAME_SUCCESS });
			listDirectory(matterID, basepath)(dispatch);
		}
		function failure() {
			console.log("rename failure");
			dispatch({ type: RENAME_FAILURE });
		}

		API.post("/directory/rename", {
			body: { matterID, directoryID, dirname },
		})
			.then((res) => {
				beginRenameJob(res.mysql.jobID, success, failure)(dispatch);
			})
			.catch(() => {
				dispatch({
					type: CREATE_NEW_FOLDER_FAILURE,
				});
			});
	};
}
export function renameDocument(
	documentID,
	newDocumentName,
	matterID,
	basepath
) {
	return function (dispatch) {
		dispatch({ type: RENAME_REQUEST });

		function success() {
			dispatch({ type: RENAME_SUCCESS });
			listDirectory(matterID, basepath)(dispatch);
		}
		function failure() {
			console.log("rename failure");
			dispatch({ type: RENAME_FAILURE });
		}

		API.post("/document/rename", {
			body: { documentID, newDocumentName },
		})
			.then(success)
			.catch(failure);
	};
}

export function updateVersionComment(
	documentID,
	versionNumber,
	versionID,
	comment
) {
	return function (dispatch) {
		dispatch({ type: UPDATE_DOCUMENT_VERSION_COMMENT_REQUEST });
		API.post("/document/version/comment", {
			body: { documentID, versionNumber, versionID, comment },
		})
			.then(() => {
				dispatch({ type: UPDATE_DOCUMENT_VERSION_COMMENT_SUCCESS });
				getDocumentDetails(documentID)(dispatch);
			})
			.catch(() => {
				dispatch({ type: UPDATE_DOCUMENT_VERSION_COMMENT_FAILURE });
			});
	};
}
function insertVersionRecords(versionData) {
	return API.put("/document/version", {
		body: { versionData },
	});
}
export function uploadNewVersion(documentID, files, matterID, callback) {
	return function (dispatch) {
		dispatch({
			type: UPLOAD_NEW_VERSION_REQUEST,
		});
		dispatch({
			type: UPLOAD_NEW_VERSION_PROGRESS,
			payload: {
				message: `Uploading new version of "${files[0].name}"`,
			},
		});
		let restrictVersionUploadIfInUseByMe = true;
		uploadFileToS3(
			null,
			files[0],
			documentID,
			"docs",
			{
				matterID: "" + matterID,
				uploadSource: "web",
				modifiedDate: "" + files[0].lastModified,
			},
			restrictVersionUploadIfInUseByMe
		)
			.then((res) =>
				insertVersionRecords([
					{
						documentID,
						versionID: res.headers["x-amz-version-id"],
						filesize: files[0].size,
					},
				])
			)
			.then(() => {
				getDocumentDetails(documentID)(dispatch);
				callback();
				dispatch({
					type: UPLOAD_NEW_VERSION_SUCCESS,
					payload: { filename: files[0].name },
				});
			})
			.catch((err) => {
				console.log("error uploading new version", err);
			});
		//check for files in directory
		//check for new file name
		//then upload the file
	};
}

export function bulkUploadMultipleFiles(
	matterID,
	basepath,
	directoryID,
	files,
	autoRename,
	callback,
	userIgnoredTheLimit
) {
	return function (dispatch) {
		// the request to /bulk/importDuplicationCheck gets too large for a post request around 30,000 files
		const fileLimit = 25000;
		if (files.length > fileLimit) {
			dispatch({
				type: BULK_FILE_UPLOAD_FAILURE,
				payload: {
					message: `Maximum number of files in one upload is ${fileLimit.toLocaleString()}. You tried to upload ${files.length.toLocaleString()}.`,
					autoHideDuration: null,
				},
			});
		} else {
			let filteredFiles = [];
			for (let i = 0; i < files.length; i++) {
				let fileName = files[i].name;
				let badFileNames = [".DS_Store", "desktop.ini", "thumbs.db"];
				if (
					!badFileNames.includes(fileName) &&
					!fileName.startsWith("~$")
				) {
					filteredFiles.push(files[i]);
				}
			}

			asyncFileAndFolderMultipleUpload(
				matterID,
				basepath,
				directoryID,
				filteredFiles,
				autoRename,
				callback,
				dispatch,
				userIgnoredTheLimit
			)
				.then(() => {
					callback();
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
}

async function asyncFileAndFolderMultipleUpload(
	matterID,
	basepath,
	directoryID,
	files,
	autoRename,
	callback,
	dispatch,
	userIgnoredTheLimit
) {
	// reset to ensure it has no value at start
	dispatch({
		type: RESET_FILE_UPLOAD_FAILURES,
	});
	// close any old snackbars
	// or new snackbars may not be shown
	dispatch({
		type: CLOSE_GLOBAL_SNACKBAR,
	});
	let topLevelFolders = new Set();
	let topLevelFiles = [];
	let filesToUpload = [];
	let fileLocationInfo = [];
	let num = files.length;
	let numPdfs = 0;
	for (let f = 0; f < num; f++) {
		let file = files[f];

		let filePath = "";
		if (file.path) {
			filePath = path.dirname(file.path).slice(1);
		} else if (file.webkitRelativePath) {
			// does not have a leading slash
			filePath = path.dirname(file.webkitRelativePath);
		}
		// normalize filePath and trim whitespace off the end
		if (filePath) {
			// if filePath is an empty string it will upload to a directory named . which would break navigation and isn't intended location
			filePath = path.normalize(filePath).trim();
		}
		// if there is an empty string, it is a file at the top level
		let topLevel = filePath.split(path.sep)[0];
		// get basepath less the first item (i.e. docs/folder1/folder2 => folder1/folder2 )
		let currentLxwPath = basepath.split(path.sep).slice(1).join("/");

		if (topLevel) {
			topLevelFolders.add(topLevel);
		}
		if (!topLevel) {
			topLevelFiles.push({ name: file.name, path: filePath });
		}
		fileLocationInfo.push({
			originalLocation: filePath,
			currentLxwPath,
		});

		let stringMatterID = "";
		if (matterID === 0) {
			stringMatterID =
				mapFirmDocsMatterID(matterID, basepath) === -1
					? "public"
					: "private";
		}
		if (ocrOnDemandAllowed(file.name)) {
			// the file is a pdf, keep count of how many
			numPdfs++;
		}

		// maybe we can get a count of how many PDFs here and if they are close to the limit we can warn them before the upload starts
		filesToUpload.push({
			lxwFilenameWithExtension: file.name,
			size: file.size,
			original: {
				LXW_MATTER_ID: stringMatterID ? stringMatterID : matterID,
				LXW_FOLDER: basepath ? currentLxwPath + filePath : filePath,
			},
		});
	}

	// ignoreOverOcrLimitWarning
	if (numPdfs > 0 && !userIgnoredTheLimit) {
		let ignoreOverOcrLimitWarning = window.localStorage.getItem(
			"ignoreOverOcrLimitWarning"
		);
		let showWarningDialog = true;
		if (ignoreOverOcrLimitWarning) {
			// has a value other than null
			let date = new Date();
			let startOfNextMonth = new Date(
				Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 1)
			);
			let ignoreWarningDate = new Date(ignoreOverOcrLimitWarning);
			if (ignoreWarningDate < startOfNextMonth) {
				showWarningDialog = false;
			} else {
				// should I delete the old one?
				window.localStorage.removeItem("ignoreOverOcrLimitWarning");
			}
		}
		if (showWarningDialog) {
			const homepageRes = await API.get("/user/homepage");
			try {
				let {
					mysql: { tenantLimits, tenantUsage, tenantFeatures },
				} = homepageRes;
				let OCR_TRANSACTIONS_THIS_MONTH =
					tenantUsage.OCR_TRANSACTIONS_THIS_MONTH;
				let ocrLimitThisMonth = tenantLimits.OCR_PER_MONTH_NET;
				let ocrIsEnabled = tenantFeatures.automaticOCR === 1;
				if (
					OCR_TRANSACTIONS_THIS_MONTH + numPdfs > ocrLimitThisMonth &&
					ocrIsEnabled
				) {
					// warn the user that all of their pdfs may not be OCR'd
					let label = "OCR Credit Warning";
					let message;
					let submitButtonLabel;
					if (OCR_TRANSACTIONS_THIS_MONTH >= ocrLimitThisMonth) {
						message = `You are uploading ${numPdfs} PDF file(s). They will not be OCR'd because your organization is out of OCR credits for the month. Please contact your firm administrator to authorize additional credits for the month.`;
						submitButtonLabel = "Upload Without OCR";
					} else {
						message = `You are uploading ${numPdfs} PDF file(s). Some of them may not be OCR'd because your organization is nearly out of OCR credits for the month. Please contact your firm administrator to authorize additional credits for the month.`;
						submitButtonLabel = "Upload Anyway";
					}
					openGlobalDialog(
						{
							label,
							mainText: <div>{message}</div>,
							type: "ocrLimitWarning", // this will populate the checkbox to ignore the warning for the rest of the month
							canSubmit: true,
							hideTextfield: true,
							submitButtonLabel,
						},
						() =>
							//call again with userIgnoredTheLimit true
							bulkUploadMultipleFiles(
								matterID,
								basepath,
								directoryID,
								files,
								autoRename,
								callback,
								dispatch,
								true
							)(dispatch),
						() => ({ canSubmit: true }),
						() => {
							dispatch({
								type: MULTIPLE_FILE_UPLOAD_CANCELED,
							});
						}
					)(dispatch);
					dispatch({
						type: MULTIPLE_FILE_UPLOAD_CANCELED,
					});
					return;
				}
			} catch (e) {
				console.log(
					"unable to determine if we should warn user about OCR limit"
				);
			}
		}
	}

	// convert back to array
	topLevelFolders = [...topLevelFolders];

	// do I need to chunk before this request? Yes if we want to allow more than ~35,000 files at once
	// it would make the renaming part substantially harder
	return API.post("/bulk/importDuplicationCheck", {
		body: {
			topLevelFolders,
			topLevelFiles,
			directoryID,
			basepath,
			autoRename,
			filesToUpload, //sent for if files/folders need to be renamed
			fileLocationInfo, //sent for if folders need to be renamed
			matterID,
		},
	})
		.then(async (res) => {
			// look for files response from the backend. should be transformed for at least the first request
			dispatch({
				type: OPEN_REACT_DIALOG,
				payload: {
					showSpinnerInTitle: true,
					title: "Uploading Files",
					mainContent: "Preparing Files for upload",
				},
			});

			// if autoRenamed get the response. Matches the format it was sent in
			if (autoRename && res.mysql.filesToUpload) {
				filesToUpload = res.mysql.filesToUpload;
			}
			const chunkSize = 500;
			let chunkedFilesForFolderCreation = chunkArray(
				filesToUpload,
				chunkSize
			);
			for (let i = 0; i < chunkedFilesForFolderCreation.length; i++) {
				// reset fileIdMap every chunk to save on memory
				let fileIdMap = {};

				await createDocumentAndFolderRecords(
					chunkedFilesForFolderCreation[i],
					chunkSize,
					i,
					files,
					fileIdMap,
					dispatch
				);
			}
		})
		.then(() => {
			// list directory will call /document/list and it will transform from 0 to public/private then
			const bulkUploadFilesFailed =
				store.getState().document.bulkUploadFilesFailed;
			listDirectory(matterID, basepath)(dispatch);
			if (bulkUploadFilesFailed.length) {
				dispatch({
					type: BULK_FILE_UPLOAD_FAILURE,
					payload: {
						autoHideDuration: null,
						message: getBulkUploadFailureMessage(),
					},
				});
				dispatch({
					type: CLOSE_REACT_DIALOG,
				});
			} else {
				dispatch({
					type: MULTIPLE_FILE_UPLOAD_SUCCESS,
					payload: {
						num: files.length,
					},
				});
				dispatch({
					type: CLOSE_REACT_DIALOG,
				});
			}
			callback();
		})
		.catch((err) => {
			const { lxwErrorCode, lxwErrorData } = err;

			const bulkUploadFilesFailed =
				store.getState().document.bulkUploadFilesFailed;
			if (bulkUploadFilesFailed.length) {
				dispatch({
					type: CLOSE_REACT_DIALOG,
				});
				dispatch({
					type: BULK_FILE_UPLOAD_FAILURE,
					payload: {
						autoHideDuration: null,
						message: getBulkUploadFailureMessage(),
					},
				});
			} else if (
				// duplicate names and folder names with special characters
				lxwErrorCode === 6502
			) {
				const { numFiles, numFolders, foldersWithBadSpecialChars } =
					lxwErrorData;
				let labelMessage;
				if (numFiles && numFolders) {
					labelMessage = `${numFiles} Duplicate File(s) Found and ${numFolders} Duplicate Folder(s) Found`;
				} else if (numFiles) {
					labelMessage = `${numFiles} Duplicate File(s) Found`;
				} else if (numFolders) {
					labelMessage = `${numFolders} Duplicate Folder(s) Found`;
				}
				openGlobalDialog(
					{
						label:
							labelMessage +
							`\n${foldersWithBadSpecialChars.length} Folders have invalid characters and will be automatically renamed`,
						mainText: `Do you want to automatically rename and upload?`,
						canSubmit: true,
						hideTextfield: true,
						submitButtonLabel: "Rename and Upload",
					},
					() =>
						//call again with autoRename true
						bulkUploadMultipleFiles(
							matterID,
							basepath,
							directoryID,
							files,
							true,
							callback,
							dispatch,
							userIgnoredTheLimit
						)(dispatch),
					() => ({ canSubmit: true }),
					() => {
						dispatch({
							type: MULTIPLE_FILE_UPLOAD_CANCELED,
						});
						dispatch({
							type: CLOSE_REACT_DIALOG,
						});
					}
				)(dispatch);
			} else if (
				// folder names have special characters
				lxwErrorCode === 6501
			) {
				const { foldersWithBadSpecialChars } = lxwErrorData;
				openGlobalDialog(
					{
						label: `${foldersWithBadSpecialChars.length} Folders have invalid characters and will be automatically renamed`,
						mainText: `Do you want to automatically rename and upload?`,
						canSubmit: true,
						hideTextfield: true,
						submitButtonLabel: "Rename and Upload",
					},
					() =>
						//call again with autoRename true
						bulkUploadMultipleFiles(
							matterID,
							basepath,
							directoryID,
							files,
							true,
							callback,
							dispatch,
							userIgnoredTheLimit
						)(dispatch),
					() => ({ canSubmit: true }),
					() => {
						dispatch({
							type: MULTIPLE_FILE_UPLOAD_CANCELED,
						});
					}
				)(dispatch);
			} else if (lxwErrorCode === 6500) {
				const { numFiles, numFolders } = lxwErrorData;
				let labelMessage;
				if (numFiles && numFolders) {
					labelMessage = `${numFiles} Duplicate File(s) Found and ${numFolders} Duplicate Folder(s) Found`;
				} else if (numFiles) {
					labelMessage = `${numFiles} Duplicate File(s) Found`;
				} else if (numFolders) {
					labelMessage = `${numFolders} Duplicate Folder(s) Found`;
				}
				openGlobalDialog(
					{
						label: labelMessage,
						mainText: `Do you want to rename duplicates and upload?`,
						canSubmit: true,
						hideTextfield: true,
						submitButtonLabel: "Rename and Upload",
					},
					() =>
						//call again with autoRename true
						bulkUploadMultipleFiles(
							matterID,
							basepath,
							directoryID,
							files,
							true,
							callback,
							dispatch,
							userIgnoredTheLimit
						)(dispatch),
					() => ({ canSubmit: true }),
					() => {
						dispatch({
							type: MULTIPLE_FILE_UPLOAD_CANCELED,
						});
						dispatch({
							type: CLOSE_REACT_DIALOG,
						});
					}
				)(dispatch);
			} else {
				// upload probably failed for unknown reason
				dispatch({
					type: MULTIPLE_FILE_UPLOAD_FAILURE,
					payload: { num: files.length },
				});
				dispatch({
					type: CLOSE_REACT_DIALOG,
				});
				console.log("generic error catch, or all files failed", err);
			}
		});
}

async function createDocumentAndFolderRecords(
	fileList,
	chunkSize,
	i,
	files,
	fileIdMap,
	dispatch
) {
	return API.post("/bulk/documentsWithFolders/import", {
		body: {
			documentList: fileList,
		},
	})
		.then(async (result) => {
			let firstNewDocumentID = result.mysql.firstNewDocumentID;

			let fileListForPresignedUrls = await fileList.map((file, j) => {
				let fileIndex = chunkSize * i + j;
				let originalFile = files[fileIndex];
				const newDocumentId = firstNewDocumentID + j;
				fileIdMap[newDocumentId] = {
					id: newDocumentId,
					originalFile,
					name: file.lxwFilenameWithExtension,
					size: file.size,
					folder: file.original.LXW_FOLDER,
				};
				return {
					id: newDocumentId,
					contentType: originalFile.type,
					mtime: originalFile.lastModified,
				};
			});
			// browsers can only make so many requests simultaneously this will limit the number of active signedUrls
			const uploadToS3ChunkSize = 4;
			let chunkedFileListForPresignedUrls = chunkArray(
				fileListForPresignedUrls,
				uploadToS3ChunkSize
			);

			for (let k = 0; k < chunkedFileListForPresignedUrls.length; k++) {
				const fileToUploadToS3 = chunkedFileListForPresignedUrls[k];
				let fileCount = files.length;
				let fileCounter = i * chunkSize + k * uploadToS3ChunkSize;
				await bulkGetSignedUrls(
					fileToUploadToS3,
					fileIdMap,
					fileCounter,
					fileCount,
					uploadToS3ChunkSize,
					dispatch
				).catch((err) => {
					return Promise.reject(err);
				});
			}
		})
		.catch(() => {
			console.error("catching err, adding files to failedList", fileList);
			dispatch({
				type: FILE_UPLOAD_FAILURES,
				payload: { bulkUploadFilesFailed: fileList },
			});
		});
}

async function bulkGetSignedUrls(
	filesToUploadToS3,
	fileIdMap,
	fileCounter,
	fileCount,
	uploadToS3ChunkSize,
	dispatch
) {
	return API.post("/bulk/multipleSignedUrlForDocumentUpload", {
		body: {
			documents: filesToUploadToS3,
			uploadSource: "web",
		},
	})
		.then((res) => {
			let signedUrls = res.mysql.urls;
			let fileNameArray = filesToUploadToS3.map(
				(f) => fileIdMap[f.id].name
			);

			let maxFile = fileCounter + uploadToS3ChunkSize;
			dispatch({
				type: UPDATE_REACT_DIALOG_CONTENT,
				payload: {
					counter: fileCounter,
					loading: false,
					mainContent: createFilesUploadingContentForDialog(
						fileNameArray,
						`Uploading files ${fileCounter + 1}-${
							maxFile > fileCount ? fileCount : maxFile
						} of ${fileCount} files:`
					),
				},
			});

			let promises = signedUrls.map((r) => {
				let myOriginalFile = fileIdMap[r.documentID];
				return uploadToS3(
					// failed files added to global list
					r.url,
					myOriginalFile,
					r.documentID,
					myOriginalFile.size,
					dispatch
				);
			});

			// ensures all promises resolve/reject before moving onto the next batch
			return Promise.allSettled(promises);
		})
		.catch(() => {
			let bulkUploadFilesFailed = filesToUploadToS3.map((f) => {
				let myFile = fileIdMap[f.id].originalFile;
				return {
					documentID: f.id,
					lxwFilenameWithExtension: myFile.name,
					original: {
						LXW_FOLDER: myFile.path
							? myFile.path
							: myFile.webkitRelativePath,
					},
				};
			});
			dispatch({
				type: FILE_UPLOAD_FAILURES,
				payload: { bulkUploadFilesFailed },
			});
		});
}

async function uploadToS3(url, myOriginalFile, documentID, fileSize, dispatch) {
	let file = myOriginalFile.originalFile;

	return uploadFileRequest(url, file)
		.then((res) => {
			// if a pdf is added to the db successfully we need to check if the tenant has enough credits to OCR them all
			// could we just get a count of how many they have when the upload process starts, but they could run out since we got the information
			// also it will be difficult to truly keep track as if the user is uploading a lot of documents, some of them may be entered into the transactions table while the upload is still ongoing

			return insertVersionRecords([
				{
					documentID: documentID,
					versionID: res.headers["x-amz-version-id"],
					filesize: fileSize,
					mtime: file.lastModified,
				},
			]);
		})
		.catch((err) => {
			// add files for if either uploadFileRequest or insertVersionRecords fails
			dispatch({
				type: FILE_UPLOAD_FAILURES,
				payload: {
					bulkUploadFilesFailed: [
						{
							documentID,
							lxwFilenameWithExtension: file.name,
							original: {
								LXW_FOLDER: file.path
									? file.path
									: file.webkitRelativePath,
							},
						},
					],
				},
			});
			console.error("adding file to failedList", err);
		});
}

function uploadFileToS3(
	basepath,
	file,
	documentID,
	rootS3Folder,
	metadata,
	restrictVersionUploadIfInUseByMe
) {
	return new Promise((resolve, reject) => {
		//first get the signed url
		API.post("/signedUrlForDocumentUpload", {
			body: {
				documentID,
				contentType: file.type,
				metadata,
				restrictVersionUploadIfInUseByMe,
			},
		})
			.then((res) => uploadFileRequest(res.mysql.url, file))
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
}

export function uploadFileRequest(url, file) {
	var instance = axios.create();

	return instance.put(url, file, {
		headers: {
			"Content-Type": file.type,
		},
	});
}
function deleteMaker(resource, deleteOrRestore) {
	return function (IDs, matterID, basepath) {
		var config = { Object: {}, Folder: {} };
		switch (resource) {
			case "document":
				config.Object[IDs[0]] = { id: IDs[0], type: "File" };
				break;
			case "email":
				config.Object[IDs[0]] = { id: IDs[0], type: "Email" };
				break;
			case "notes":
				config.Object[IDs[0]] = { id: IDs[0], type: "Note" };
				break;
			case "directory":
				config.Folder[IDs[0]] = { id: IDs[0], type: "Folder" };
				break;
			default:
				console.log("I should not be seen");
		}
		if (deleteOrRestore === "delete") {
			return deleteMultiselect(config, matterID, basepath);
		} else if (deleteOrRestore === "restore") {
			return restoreMultiselect(config, matterID, basepath);
		}
	};
}
const deleteDocuments = deleteMaker("document", "delete");
const restoreDocuments = deleteMaker("document", "restore");
const deleteFolders = deleteMaker("directory", "delete");
const restoreFolders = deleteMaker("directory", "restore");
const deleteEmails = deleteMaker("email", "delete");
const restoreEmails = deleteMaker("email", "restore");
const deleteNotes = deleteMaker("notes", "delete");
const restoreNotes = deleteMaker("notes", "restore");
const textAreaLabel = "";
const textAreaName = "";
export {
	deleteEmails,
	restoreEmails,
	deleteNotes,
	restoreNotes,
	deleteDocuments,
	restoreDocuments,
	deleteFolders,
	restoreFolders,
	textAreaLabel,
	textAreaName,
};

export function deleteVersion(key, documentID, versionID, versionNumber) {
	return function (dispatch) {
		openGlobalDialog(
			{
				label: `Permanently Delete this Document Version?`,
				mainText: `This will permanently delete v${versionNumber} of this document.  This operation cannot be undone.  Are you sure?`,
				canSubmit: true,
				hideTextfield: true,
				submitButtonLabel: "Permanently Delete this Version",
			},
			(state) => {
				dispatch({ type: DELETE_DOCUMENT_VERSION_REQUEST });
				deleteVersionRecord(documentID, versionID)
					.then(() => {
						dispatch({ type: DELETE_DOCUMENT_VERSION_SUCCESS });
						getDocumentDetails(key)(dispatch);
					})
					.catch((err) => {
						dispatch({ type: DELETE_DOCUMENT_VERSION_FAILURE });
					})
					.finally(() => {
						getDocumentDetails(key)(dispatch);
					});
			}
		)(dispatch);
	};
}
function deleteVersionRecord(documentID, versionID) {
	return API.post("/document/version/delete", {
		queryParams: { documentID, versionID },
	});
}

function updateDocumentPropertiesHelper(documentProperties) {
	const newDocumentProperties = {
		...documentProperties,
		documentName:
			documentProperties.extension === ""
				? documentProperties.basename
				: documentProperties.basename +
					"." +
					documentProperties.extension,
	};
	if (
		documentProperties.documentTags &&
		documentProperties.documentTags.length
	) {
		newDocumentProperties.tags = documentProperties.documentTags.map(
			(tag) => tag.label
		);
	} else {
		newDocumentProperties.tags = [];
	}

	if (documentProperties.documentStatus) {
		if (documentProperties.documentStatus.__isNew__) {
			newDocumentProperties.newDocumentStatus =
				documentProperties.documentStatus.value;
		} else {
			newDocumentProperties.existingDocumentStatusID =
				documentProperties.documentStatus.id;
		}
	}
	if (documentProperties.documentType) {
		if (documentProperties.documentType.__isNew__) {
			newDocumentProperties.newDocumentType =
				documentProperties.documentType.value;
		} else {
			newDocumentProperties.existingDocumentTypeID =
				documentProperties.documentType.id;
		}
	}

	return newDocumentProperties;
}
export function updateDocumentProperties(
	documentID,
	documentProperties,
	containingDirectoryLocation
) {
	return function (dispatch) {
		const newProps = updateDocumentPropertiesHelper(documentProperties);
		newProps.documentName = newProps.extension
			? newProps.basename + "." + newProps.extension
			: newProps.basename;
		dispatch({ type: UPDATE_DOCUMENT_PROPERTIES_REQUEST });
		API.post("/document", {
			body: { documentID, documentProperties: newProps },
		})
			.then(() => {
				dispatch({ type: UPDATE_DOCUMENT_PROPERTIES_SUCCESS });
				window.safeGoBack(containingDirectoryLocation);
			})
			.catch((err) => {
				dispatch({ type: UPDATE_DOCUMENT_PROPERTIES_FAILURE });
				console.log(err);
			});
	};
}

export function setFavorite(documentID, isFavorite, matterID, basepath) {
	return function (dispatch) {
		API.post("/document/favorite", { body: { documentID, isFavorite } })
			.then(() => {
				dispatch({
					type: "TOGGLE_FAVORITE_SUCCESS",
				});
				if (matterID !== undefined && basepath !== undefined) {
					//hack way to handle case of setting favorite from the document properties screen.  Document Properties does not have matterID
					listDirectory(matterID, basepath)(dispatch);
				}
			})
			.catch(() => {
				dispatch({
					type: "TOGGLE_FAVORITE_FAILURE",
				});
			});
	};
}
export function setFavoriteFolder(folderID, isFavorite, matterID, basepath) {
	return function (dispatch) {
		API.post("/document/favoriteFolder", { body: { folderID, isFavorite } })
			.then(() => {
				dispatch({
					type: "TOGGLE_FAVORITE_SUCCESS",
				});
				if (matterID !== undefined && basepath !== undefined) {
					//hack way to handle case of setting favorite from the document properties screen.  Document Properties does not have matterID
					listDirectory(matterID, basepath)(dispatch);
				}
			})
			.catch(() => {
				dispatch({
					type: "TOGGLE_FAVORITE_FAILURE",
				});
			});
	};
}

export function unmountDocumentProperties() {
	return function (dispatch) {
		dispatch({
			type: UNMOUNT_DOCUMENT_DETAILS,
		});
	};
}
export function setLockStatus(documentID, lockStatus, basepath, matterID) {
	return function (dispatch) {
		API.post("/document/lock", {
			body: {
				documentID,
				lockStatus,
			},
		})
			.then(() => {
				dispatch({
					type: SET_DOCUMENT_LOCK_SUCCESS,
					payload: { lockStatus },
				});
				if (basepath) {
					//hacky way to determine if user is on document list or document properties
					listDirectory(matterID, basepath)(dispatch);
				} else {
					getDocumentDetails(documentID)(dispatch);
				}
			})
			.catch(() => {});
	};
}
export function getRecentDocsAndMatters() {
	return function (dispatch, getState) {
		let recentMatterIDs = getRecentlyViewMatters()(dispatch, getState);
		dispatch({ type: FETCH_RECENT_DOCS_REQUEST_NOGLOBAL });
		API.post("/document/recentDocsAndMatters", {
			body: { recentMatterIDs },
		})
			.then((res) => {
				dispatch({
					type: FETCH_RECENT_DOCS_SUCCESS_NOGLOBAL,
					payload: res.mysql,
				});
			})
			.catch((err) => {
				console.error("error getting /document/recentDocsAndMatters");
			});
	};
}
export function getFavoriteDocs() {
	return function (dispatch) {
		dispatch({ type: FETCH_FAVORITE_DOCS_REQUEST_NOGLOBAL });
		API.get("/document/listFavorites")
			.then((res) => {
				dispatch({
					type: FETCH_FAVORITE_DOCS_SUCCESS_NOGLOBAL,
					payload: res.mysql,
				});
			})
			.catch((err) => {
				console.error("error getting /document/listFavorites");
			});
	};
}

export function aiConversationAboutDocument({
	documentID,
	userPrompt,
	priorMessages,
}) {
	//return function(dispatch) {
	return API.post("/ai/conversation", {
		body: { documentID, userPrompt, priorMessages },
	}).then((res) => {
		return res.mysql;
	});
	//};
}

export function summarizeDocument(documentID, versionNumber) {
	return API.post("/ai/summarize", {
		body: { documentID, versionNumber },
	}).then((res) => {
		return res.mysql;
	});
}

export function suggestQuestions(documentID, versionNumber) {
	return API.post("/ai/suggest", {
		body: { documentID, versionNumber },
	}).then((res) => {
		return res.mysql;
	});
}
export function getContentForDocumentPreview(
	documentID,
	versionNumber,
	emailID,
	previewType
) {
	return function (dispatch) {
		dispatch({ type: FETCH_DOCUMENT_PREVIEW_CONTENT });
		var apiCall, documentPreviewContent;
		if (previewType === "Document") {
			apiCall = API.post("/document/preview", {
				body: {
					documentID,
					versionNumber,
				},
			});
		} else if (previewType === "Email") {
			apiCall = API.post("/email/preview", {
				body: {
					emailID,
				},
			});
		} else if (previewType === "Image") {
			apiCall = API.get("/signedGetIgnoreLock", {
				queryParams: { documentID, versionNumber },
			});
		}
		apiCall
			.then((res) => {
				if (res && res.mysql) {
					if (previewType === "Image") {
						documentPreviewContent = {
							url: res.mysql.url,
						};
						dispatch({
							type: DOCUMENT_PREVIEW_CONTENT_SUCCESS,
							payload: documentPreviewContent,
						});
					} else {
						dispatch({
							type: DOCUMENT_PREVIEW_CONTENT_SUCCESS,
							payload: res.mysql,
						});
					}
				} else {
					dispatch({
						type: DOCUMENT_PREVIEW_CONTENT_FAILURE,
					});
				}
			})
			.catch(() => {
				dispatch({ type: DOCUMENT_PREVIEW_CONTENT_FAILURE });
			});
	};
}
export function copyDocLinkToClipboard(documentID) {
	return function (dispatch) {
		let docUrl = window.location.origin + "/docs/" + documentID;
		navigator.clipboard
			.writeText(docUrl)
			.then(() => {
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						message: "Document URL copied to clipboard",
						variant: "success",
					},
				});
			})
			.catch(() => {
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						message: "Unable to copy Document URL to clipboard",
						variant: "error",
					},
				});
			});
	};
}
