import React from "react";

import caFlag from "../svg/ca.svg";
import usFlag from "../svg/us.svg";

const flags = {
	CA: caFlag,
	US: usFlag,
};

export const Flag = (props) => {
	const flag = flags[props.region];
	if (!flag) {
		return null;
	}
	return (
		<img
			src={flag}
			alt={props.region}
			style={{
				width: 25,
				marginRight: 5,
				marginTop: -3,
			}}
		/>
	);
};
