import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonWithRouter, ButtonWithIcon } from "./ButtonWithIcon";

//wrapper for a form component
function TwoColumnWrapper(props) {
	return (
		<div className="noDefaultMargin" style={{ margin: "0 30px 30px 30px" }}>
			<div
				style={{
					height: 64,
					lineHeight: "64px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					gap: 8,
				}}
			>
				<div style={{ minWidth: "fit-content" }}>
					<FontAwesomeIcon
						icon={props.iconName || "briefcase"}
						size="1x"
					/>
					<span
						style={{
							fontFamily: "MainFont-SemiBold",
							fontSize: 16,
							marginLeft: 8,
						}}
					>
						{props.headingText}
					</span>
				</div>
				{props.rightHeading ? <div>{props.rightHeading}</div> : null}
			</div>
			<div
				style={{
					backgroundColor: "var(--lw-light-grey-a)",
					padding: 30,
				}}
			>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "1fr 1fr",
						gridGap: 50,
					}}
				>
					<div style={{ maxWidth: "45vw" }}>{props.leftChildren}</div>
					<div style={{ maxWidth: "45vw" }}>
						{props.rightChildren}
					</div>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<ButtonWithRouter
						type="go-back"
						defaultGoBackLocation={props.defaultGoBackLocation}
						buttonText={props.cancelButtonText || "Cancel"}
						iconName="times"
						style={{ marginRight: 8 }}
					/>
					<ButtonWithIcon
						handler={() => {
							props.canSubmit && props.submit();
						}}
						disabledHoverText={props.submitDisabledHoverText}
						buttonText={props.submitButtonText}
						className="blue"
						disabled={!props.canSubmit}
						iconName="check"
					/>
				</div>
			</div>
		</div>
	);
}

export default TwoColumnWrapper;
