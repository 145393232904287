/*
	Handles New and Edit matter functionality.  props.editMode determines which.
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
	createNewMatter,
	updateMatter,
	unmountNewEditMatter,
} from "../actions/matterActions";

import {
	getEverythingForEditTenant,
	getEverythingForNewTenant,
	editTenant,
	createTenant,
	unmountNewEditTenant,
	checkIfTenantExists,
} from "../actions/adminActions";
import TwoColumnWrapper from "./TwoColumnWrapper";
import { setSubheaderText } from "../actions/navigateActions";
import { LabeledTextInput } from "./TextInput";
import { LabeledTextInputWithValidation } from "./TextFieldWithValidation";

import { LabeledDropDownSelect } from "./DropDownSelect";

import { humanFileSize, numberWithCommas } from "../utilities/utilities";

import lxwConstants from "../config/lexworkplace-constants";
const lexWorkplaceEditions = lxwConstants.lexWorkplaceEditions;

const initialState = {
	name: "",
	tenantID: null,
	customerID: "",
	usernamePrefix: "",
};

const regions = {
	US: "U.S.",
	CA: "Canada",
};

const makeDataUsageMenuOption = (gigabytes) => {
	const limitInBytes = gigabytes * 1000 * 1000 * 1000;
	return { label: humanFileSize(limitInBytes, true), value: gigabytes };
};
const makeOCRPerMonthBaselineMenuOption = (ocrOperationsPerMonth) => {
	return {
		label: numberWithCommas(ocrOperationsPerMonth),
		value: ocrOperationsPerMonth,
	};
};

const makeEnabledDisabledMenuOptions = (enabled) => {
	return {
		label: enabled ? "Enabled" : "Disabled",
		value: enabled,
	};
};
const makeRegionMenuOption = (regionName) => {
	if (!regionName) {
		return false;
	}
	return {
		label: regions[regionName],
		value: regionName,
	};
};
const lxwRegionOptions = (() => {
	return ["US", "CA"].map(makeRegionMenuOption);
})();
const storageLimitOptions = (() => {
	var out = [];
	for (let gigabytes = 500; gigabytes <= 20000; gigabytes += 100) {
		out.push(makeDataUsageMenuOption(gigabytes));
	}
	return out;
})();

const ocrPerMonthOptions = (() => {
	var out = [];

	for (let val = 2500; val <= 50000; val += 2500) {
		out.push(makeOCRPerMonthBaselineMenuOption(val));
	}
	return out;
})();

const lxwEditionOptions = (() => {
	return Object.keys(lexWorkplaceEditions.options).map((editionCode) => {
		return {
			label: lexWorkplaceEditions.options[editionCode].display,
			value: editionCode,
		};
	});
})();

const ocrEnabledOptions = [
	makeEnabledDisabledMenuOptions(true),
	makeEnabledDisabledMenuOptions(false),
];

const literaCompareEnabledOptions = [
	makeEnabledDisabledMenuOptions(true),
	makeEnabledDisabledMenuOptions(false),
];

const inlineCompareEnabledOptions = [
	makeEnabledDisabledMenuOptions(true),
	makeEnabledDisabledMenuOptions(false),
];

const makeEditionMenuOption = (editionCode) => {
	return lexWorkplaceEditions.options[editionCode]
		? {
				label: lexWorkplaceEditions.options[editionCode].display,
				value: editionCode,
			}
		: { label: "Unknown", value: "unknown" };
};

class NewTenant extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			regionName: "",
			customerID: "",
			usernamePrefix: "",
			tenantID: null,
			loading: true,
			firmNameIsValid: false,
			tenantIDisValid: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.canSubmit = this.canSubmit.bind(this);
	}
	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = props.editMode
				? {
						loading: false,
						name: props.firmDetails.name,
						regionName: makeRegionMenuOption(props.tenantRegion),
						customerID: props.firmDetails.customerID,
						usernamePrefix: props.firmDetails.usernamePrefix,
						tenantID: props.firmDetails.id,
						storageLimitGB: makeDataUsageMenuOption(
							props.usageLimits.STORAGE_LIMIT_GB
						),
						lxwEdition: makeEditionMenuOption(
							props.firmDetails.lxwEdition
						),
						ocrEnabled: makeEnabledDisabledMenuOptions(
							props.tenantFeatures.automaticOCR
						),
						ocrPerMonthBaseline: makeOCRPerMonthBaselineMenuOption(
							props.usageLimits.OCR_PER_MONTH_BASELINE
						),
						literaDocumentCompareEnabled:
							makeEnabledDisabledMenuOptions(
								props.tenantFeatures
									.literaDocumentCompareEnabled
							),
						inlineDocumentCompareEnabled:
							makeEnabledDisabledMenuOptions(
								props.tenantFeatures
									.inlineDocumentCompareEnabled
							),
					}
				: {
						...initialState,
						tenantID: props.firmDetails
							? props.firmDetails.id
							: 420,
						regionName: "",
						loading: false,
						storageLimitGB: makeDataUsageMenuOption(
							props.newFirmDefaultStorageLimitGB
						),
						lxwEdition: makeEditionMenuOption(
							props.newFirmEditionCode
						),
						ocrEnabled: makeEnabledDisabledMenuOptions(
							props.newFirmDefaultOCREnabled
						),
						ocrPerMonthBaseline: makeOCRPerMonthBaselineMenuOption(
							props.newFirmDefaultOCRPerMonthBaseline
						),
						literaDocumentCompareEnabled:
							makeEnabledDisabledMenuOptions(
								props.newFirmDefaultLiteraDocumentCompare
							),
						inlineDocumentCompareEnabled:
							makeEnabledDisabledMenuOptions(
								props.newFirmDefaultInlineDocumentCompare
							),
					};
			return newState;
		} else {
			return null;
		}
	}
	componentDidMount() {
		if (this.props.editMode) {
			this.props.getEverythingForEditTenant(
				this.props.match.params.tenantID
			);
		} else {
			this.props.getEverythingForNewTenant();
			this.props.setSubheaderText("New Firm");
		}
	}
	componentWillUnmount() {
		this.props.unmountNewEditTenant();
	}
	handleChange(key, value) {
		this.setState({ [key]: value });
	}
	submit() {
		if (this.props.editMode) {
			this.props.editTenant({
				manualTenantID: this.props.match.params.tenantID,
				name: this.state.name,
				storageLimitGB: this.state.storageLimitGB.value,
				lxwEdition: this.state.lxwEdition.value,
				ocrEnabled: this.state.ocrEnabled.value,
				ocrPerMonthBaseline: this.state.ocrPerMonthBaseline.value,
				literaDocumentCompareEnabled:
					this.state.literaDocumentCompareEnabled.value,
				inlineDocumentCompareEnabled:
					this.state.inlineDocumentCompareEnabled.value,
			});
		} else {
			this.props.createTenant({
				manualTenantID: this.state.tenantID,
				name: this.state.name,
				regionName: this.state.regionName.value,
				customerID: this.state.customerID,
				usernamePrefix: this.state.usernamePrefix,
				storageLimitGB: this.state.storageLimitGB.value,
				lxwEdition: this.state.lxwEdition.value,
				ocrEnabled: this.state.ocrEnabled.value,
				ocrPerMonthBaseline: this.state.ocrPerMonthBaseline.value,
				literaDocumentCompareEnabled:
					this.state.literaDocumentCompareEnabled.value,
				inlineDocumentCompareEnabled:
					this.state.inlineDocumentCompareEnabled.value,
			});
		}
	}
	canSubmit() {
		return (
			this.state.name !== "" &&
			this.state.firmNameIsValid &&
			this.state.customerID !== "" &&
			this.state.tenantIDisValid &&
			this.state.usernamePrefix !== "" &&
			!!this.state.regionName
		);
	}
	getSubmitDisabledText() {
		let errorArray = [];
		if (this.state.name === "") {
			errorArray.push("Firm Name is required");
		}
		if (!this.state.firmNameIsValid) {
			errorArray.push("Firm Name is invalid");
		}
		if (this.state.customerID === "") {
			errorArray.push("Customer ID is required");
		}
		if (!this.state.tenantIDisValid) {
			errorArray.push("Tenant ID is invalid");
		}
		if (this.state.usernamePrefix === "") {
			errorArray.push("Username Prefix is required");
		}
		if (!this.state.regionName) {
			errorArray.push("Region is required");
		}
		if (errorArray.length) {
			return errorArray.join("\n");
		}
		return false;
	}
	render() {
		if (this.props.navigateAwayFromCreatePage) {
			return (
				<Redirect
					to={
						this.props.editMode
							? "/admin"
							: `/admin/tenant/${this.state.tenantID}/users`
					}
				/>
			);
		}
		if (this.state.loading) {
			return <div />;
		} else {
			return (
				<TwoColumnWrapper
					headingText="Firm Information"
					iconName="briefcase"
					canSubmit={this.canSubmit()}
					submitDisabledHoverText={this.getSubmitDisabledText()}
					submit={this.submit.bind(this)}
					submitButtonText={
						this.props.editMode ? "Update Firm" : "Create Firm"
					}
					leftChildren={
						<div>
							<LabeledTextInputWithValidation
								label="Firm Name"
								name="name"
								placeholder="New Firm Name"
								defaultValue={
									this.props.editMode
										? this.props.firmDetails.name
										: null
								}
								onChange={this.handleChange}
								autocomplete="off"
								syncValidationError={(enteredFirmName) => {
									if (
										enteredFirmName === "" ||
										enteredFirmName.match(/^\s/) ||
										enteredFirmName.match(/\s$/)
									) {
										return "Firm Name cannot start or end with whitespace";
									}
									return false;
								}}
								fieldIsValidAndReadyChanged={(
									firmNameIsValid
								) => {
									this.setState({
										firmNameIsValid,
									});
								}}
							/>
							<LabeledDropDownSelect
								label="Region"
								name="regionName"
								placeholder="Please select a region"
								isDisabled={this.props.editMode}
								isAsync
								options={lxwRegionOptions}
								defaultValue={
									this.props.editMode
										? [
												makeRegionMenuOption(
													this.props.tenantRegion
												),
											]
										: false
								}
								handler={this.handleChange}
							/>
							<LabeledTextInput
								label="Internal Customer ID"
								name="customerID"
								placeholder="Customer ID"
								disabled={this.props.editMode}
								defaultValue={
									this.props.editMode
										? this.props.firmDetails.customerID
										: null
								}
								onChange={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="Storage Limit"
								name="storageLimitGB"
								placeholder="Storage Limit"
								isAsync
								limitNumber={100}
								options={storageLimitOptions}
								defaultValue={
									this.props.editMode &&
									this.props.usageLimits
										? [
												makeDataUsageMenuOption(
													this.props.usageLimits
														.STORAGE_LIMIT_GB
												),
											]
										: [
												makeDataUsageMenuOption(
													this.props
														.newFirmDefaultStorageLimitGB
												),
											]
								}
								handler={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="Automatic OCR"
								name="ocrEnabled"
								placeholder="OCR"
								isAsync
								options={ocrEnabledOptions}
								defaultValue={[
									makeEnabledDisabledMenuOptions(
										this.props.editMode
											? this.props.tenantFeatures
													.automaticOCR
											: this.props
													.newFirmDefaultOCREnabled
									),
								]}
								handler={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="OCR Operations Per Month Baseline"
								name="ocrPerMonthBaseline"
								placeholder="OCR Operations per Month"
								isAsync
								limitNumber={100}
								options={ocrPerMonthOptions}
								defaultValue={
									this.props.editMode &&
									this.props.usageLimits
										? [
												makeOCRPerMonthBaselineMenuOption(
													this.props.usageLimits
														.OCR_PER_MONTH_BASELINE
												),
											]
										: [
												makeOCRPerMonthBaselineMenuOption(
													this.props
														.newFirmDefaultOCRPerMonthBaseline
												),
											]
								}
								handler={this.handleChange}
							/>
						</div>
					}
					rightChildren={
						<div>
							<div>
								{this.props.failedCreateAttemptDuplicateID && (
									<div style={{ color: "red" }}>
										ERROR! this tenantID is already in use
										by a different firm
									</div>
								)}
								<LabeledTextInputWithValidation
									label="Tenant ID (auto populated with next available)"
									name="tenantID"
									placeholder="New Tenant ID"
									disabled={this.props.editMode}
									defaultValue={this.props.firmDetails.id}
									onChange={this.handleChange}
									autocomplete="off"
									syncValidationError={(enteredTenantID) => {
										if (!enteredTenantID.match(/^\d+$/)) {
											return "tenantID must be a number";
										}
										return false;
									}}
									asyncValidationError={(newValue) => {
										return checkIfTenantExists(
											newValue
										).then((res) => {
											const { tenantIDinUse } = res.mysql;
											if (tenantIDinUse) {
												return "This tenantID is already in use";
											}
											//returning false means no error
											return false;
										});
									}}
									fieldIsValidAndReadyChanged={(
										tenantIDisValid
									) => {
										this.setState({
											tenantIDisValid,
										});
									}}
								/>
							</div>
							<LabeledTextInput
								label="Username Prefix (use firm email domain)"
								name="usernamePrefix"
								placeholder="firm email domain"
								disabled={this.props.editMode}
								defaultValue={
									this.props.editMode
										? this.props.firmDetails.usernamePrefix
										: null
								}
								onChange={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="Edition"
								name="lxwEdition"
								placeholder="Edition"
								isAsync
								options={lxwEditionOptions}
								defaultValue={[
									makeEditionMenuOption(
										this.props.editMode
											? this.props.firmDetails.lxwEdition
											: this.props.newFirmEditionCode
									),

									/*this.props.editMode ? makeDataUsageMenuOption(
													this.props.usageLimits
														.STORAGE_LIMIT_GB
												) : { label: "Core", value: "CORE" },*/
								]}
								handler={this.handleChange}
							/>

							<LabeledDropDownSelect
								label="Litera Document Compare"
								name="literaDocumentCompareEnabled"
								placeholder="Litera"
								isAsync
								options={literaCompareEnabledOptions}
								defaultValue={[
									makeEnabledDisabledMenuOptions(
										this.props.editMode
											? this.props.tenantFeatures
													.literaDocumentCompareEnabled
											: this.props
													.newFirmDefaultLiteraDocumentCompare
									),
								]}
								handler={this.handleChange}
							/>
							<LabeledDropDownSelect
								label="Inline Document Compare"
								name="inlineDocumentCompareEnabled"
								placeholder="Inline"
								isAsync
								options={inlineCompareEnabledOptions}
								defaultValue={[
									makeEnabledDisabledMenuOptions(
										this.props.editMode
											? this.props.tenantFeatures
													.inlineDocumentCompareEnabled
											: this.props
													.newFirmDefaultInlineDocumentCompare
									),
								]}
								handler={this.handleChange}
							/>
						</div>
					}
				/>
			);
		}
	}
}
const mapStateToProps = (state, ownProps) => {
	var newFirmEditionCode = ownProps.match.params.newFirmEditionCode;
	var newFirmDefaultStorageLimitGB,
		newFirmDefaultOCREnabled,
		newFirmDefaultOCRPerMonthBaseline,
		newFirmDefaultInlineDocumentCompare,
		newFirmDefaultLiteraDocumentCompare;
	if (newFirmEditionCode) {
		const { defaultValues } =
			lexWorkplaceEditions.options[newFirmEditionCode];
		newFirmDefaultStorageLimitGB = defaultValues.limits.STORAGE_LIMIT_GB;
		newFirmDefaultOCRPerMonthBaseline =
			defaultValues.limits.OCR_PER_MONTH_BASELINE;
		newFirmDefaultOCREnabled = defaultValues.features.automaticOCR;
		newFirmDefaultLiteraDocumentCompare =
			defaultValues.features.literaDocumentCompareEnabled;
		newFirmDefaultInlineDocumentCompare =
			defaultValues.features.inlineDocumentCompareEnabled;
	}

	return {
		availableMatterTags: state.matter.availableMatterTags,
		availableMatterTypes: state.matter.availableMatterTypes,
		availableAssignedStaff: state.matter.availableAssignedStaff,
		availableOfficeBranches: state.matter.availableOfficeBranches,
		availableClients: state.matter.availableClients,
		matterDetails: state.matter.matterDetails,
		clientDetails: state.client.clientDetails,
		firmDetails: state.admin.firmDetails,
		tenantRegion: state.admin.tenantRegion,
		usageLimits: state.admin.usageLimits,
		tenantFeatures: state.admin.tenantFeatures,
		loading: state.admin.newEditTenantLoading,
		failedCreateAttemptDuplicateID:
			state.admin.failedCreateAttemptDuplicateID,
		navigateAwayFromCreatePage: state.admin.navigateAwayFromCreatePage,
		newFirmEditionCode,
		newFirmDefaultStorageLimitGB,
		newFirmDefaultOCREnabled,
		newFirmDefaultOCRPerMonthBaseline,
		newFirmDefaultLiteraDocumentCompare,
		newFirmDefaultInlineDocumentCompare,
	};
};

export default connect(mapStateToProps, {
	createNewMatter,
	updateMatter,
	getEverythingForNewTenant,
	getEverythingForEditTenant,
	unmountNewEditMatter,
	setSubheaderText,
	editTenant,
	createTenant,
	unmountNewEditTenant,
	checkIfTenantExists,
})(NewTenant);
