import {
	USER_LOGGED_IN,
	USER_LOGGED_OUT,
	USER_UNAUTHENTICATED,
	USER_LOGIN_UNSUCCESSFUL,
	USER_FORGOT_PASSWORD_REQUEST_SUCCESS,
	USER_FORGOT_PASSWORD_REQUEST_FAILURE,
	NEW_PASSWORD_REQUIRED,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILURE,
	SOFTWARE_TOKEN_MFA_CHALLENGE,
	UPDATE_ATTRIBUTES_SUCCESS,
	MFA_VERIFICATION_ERROR,
	MFA_SESSION_EXPIRED,
	TOTP_SETUP_CODE_GENERATED,
	VERIFY_TOTP_TOKEN_SUCCESS,
	VERIFY_TOTP_TOKEN_FAILURE,
	INIT_MFA,
	SET_REDIRECT_URL,
	GET_USER_DATA_SUCCESS,
	GET_EXTERNAL_SAVED_EMAIL_ADDRESSES_SUCCESS,
	DELETE_EXTERNAL_EMAIL_REQUEST,
	DELETE_EXTERNAL_EMAIL_SUCCESS,
	DELETE_EXTERNAL_EMAIL_FAILURE,
} from "../actions/types";

const initialState = {
	userDisplayName: "Guest",
	userID: null,
	userEmail: "",
	authState: USER_UNAUTHENTICATED,
	changePasswordFlow: "",
	isLoggedIn: false,
	determiningAuthStatus: true, // this is set to false after the first auth check is complete
	loading: true,
	redirectLocation: "/",
	username: "",
	errorMessage: "",
	cognitoUser: {},
	mfaSetupCode: "",
	infoMessage: "",
	jwt: "",
	firmName: "",
	isFirmAdmin: false,
	isUptimeAdmin: false,
	hasPermissionsAccess: false,
	canOverrideDocumentLocks: false,
	canAccessPrivateFirmDocs: false,
	canEditExchangeUrl: false,
	canEditIntegrationSettings: false,
	canRequestMonthlyOCRLimitIncrease: false,
	externalEmailAddresses: [],
	emailAddressListLoading: false,
	allMatterNames: [],
	allClientNames: [],
	userRegion: "initializing...",
};

export default function (state = initialState, action) {
	const mixin = { ...state, authState: action.type };
	switch (action.type) {
		case USER_LOGGED_IN: {
			const emailVerified =
				action.payload.attributes.email_verified !== "false";

			return {
				...mixin,
				userDisplayName:
					action.payload.attributes.name ||
					action.payload.attributes.email,
				isLoggedIn: true,
				determiningAuthStatus: false,
				userEmail: action.payload.attributes.email,
				emailVerified,
				cognitoUser: action.payload.cognitoUser,
				changePasswordFlow: "loggedIn",
				username: action.payload.cognitoUser.username,
				jwt: action.payload.jwt,
				refresh: action.payload.refresh,
			};
		}
		case SOFTWARE_TOKEN_MFA_CHALLENGE:
			return {
				...mixin,
				cognitoUser: action.payload.cognitoUser,
			};
		case MFA_VERIFICATION_ERROR: {
			return {
				...mixin,
				errorMessage:
					"Invalid MFA code.  Please wait for a new code and try again.",
			};
		}
		case MFA_SESSION_EXPIRED:
			// the user took too long to enter the MFA code.  Cognito has expired the session
			// and they need to re-enter their credentials
			return {
				...mixin,
				authState: USER_UNAUTHENTICATED,
				infoMessage: "Please re-enter credentials",
			};
		case USER_UNAUTHENTICATED:
			return {
				...mixin,
				userDisplayName: "Guest",
				isLoggedIn: false,
				determiningAuthStatus: false,
				loading: false,
			};
		case USER_LOGGED_OUT:
			return {
				...mixin,
				loading: false,
				isLoggedIn: false,
				determiningAuthStatus: false,
				userDisplayName: action.payload.userDisplayName,
			};
		case USER_LOGIN_UNSUCCESSFUL:
			return {
				...mixin,
				determiningAuthStatus: false,
				errorMessage: "Incorrect Username or Password",
			};
		case NEW_PASSWORD_REQUIRED:
			return {
				...mixin,
				cognitoUser: action.payload.cognitoUser,
				changePasswordFlow: "forced",
			};
		case CHANGE_PASSWORD_SUCCESS:
			return {
				...mixin,
				isLoggedIn: action.payload.isLoggedIn,
			};
		case CHANGE_PASSWORD_FAILURE:
			return {
				...mixin,
				errorMessage:
					action.payload.errorMessage || "Could not change password",
			};
		case USER_FORGOT_PASSWORD_REQUEST_SUCCESS:
			return {
				...mixin,
				username: action.payload.username,
				changePasswordFlow: "forgot",
			};
		case USER_FORGOT_PASSWORD_REQUEST_FAILURE:
			return {
				...mixin,
				errorMessage: "User not found",
			};
		case UPDATE_ATTRIBUTES_SUCCESS:
			var attributes = action.payload.attributes.reduce((acc, cur) => {
				return { ...acc, [cur.Name]: cur.Value };
			}, {});
			return {
				...mixin,
				userDisplayName: attributes.name,
				userEmail: attributes.email,
			};
		case INIT_MFA:
			return {
				...state,
				authState: state.isLoggedIn
					? USER_LOGGED_IN
					: USER_UNAUTHENTICATED,
				mfaSetupCode: "",
				infoMessage: "",
				errorMessage: "",
			};
		case TOTP_SETUP_CODE_GENERATED:
			return {
				...mixin,
				mfaSetupCode: action.payload.code,
				infoMessage:
					"Scan this QR code using your MFA client app (Google Authenticator), and enter the generated code below to complete MFA setup",
			};
		case VERIFY_TOTP_TOKEN_SUCCESS:
			return {
				...mixin,
				infoMessage:
					"Multi-factor Authentication has been successfully configured",
			};
		case VERIFY_TOTP_TOKEN_FAILURE:
			return {
				...mixin,
				errorMessage: "There was a problem verifying the MFA code",
			};
		case SET_REDIRECT_URL: {
			return { ...state, redirectLocation: action.payload.url };
		}
		case GET_USER_DATA_SUCCESS: {
			const { user, groups } = action.payload;
			const isFirmAdmin =
				groups.filter((g) => g.type === "FIRM_ADMIN").length > 0;
			const isUptimeAdmin = user.type === "uptimeAdmin";
			return {
				...state,
				loading: false,
				userID: user.id || -1,
				firmName: user.tenantName,
				lxwEdition: user.lxwEdition,
				userDisplayName: user.displayName,
				userType: user.type,
				userRegion: user.userRegion,
				isFirmAdmin,
				isUptimeAdmin,
				hasPermissionsAccess: isFirmAdmin || isUptimeAdmin,
				canOverrideDocumentLocks: isFirmAdmin || isUptimeAdmin,
				canAccessPrivateFirmDocs: isFirmAdmin || isUptimeAdmin,
				canEditExchangeUrl: isFirmAdmin || isUptimeAdmin,
				canEditIntegrationSettings: isFirmAdmin || isUptimeAdmin,
				canRequestMonthlyOCRLimitIncrease: isFirmAdmin || isUptimeAdmin,
				canEditDocumentStampingSettings: isFirmAdmin || isUptimeAdmin,
			};
		}
		case DELETE_EXTERNAL_EMAIL_REQUEST: {
			return { ...state, emailAddressListLoading: true };
		}
		case DELETE_EXTERNAL_EMAIL_SUCCESS: {
			return { ...state, emailAddressListLoading: false };
		}
		case DELETE_EXTERNAL_EMAIL_FAILURE: {
			return { ...state, emailAddressListLoading: false };
		}
		case GET_EXTERNAL_SAVED_EMAIL_ADDRESSES_SUCCESS: {
			return {
				...state,
				externalEmailAddresses: action.payload,
			};
		}
		case "GET_ALL_MATTERS_SUCCESS": {
			return { ...state, allMatterNames: action.payload };
		}
		case "GET_ALL_CLIENTS_SUCCESS": {
			return { ...state, allClientNames: action.payload };
		}
		default:
			return state;
	}
}
