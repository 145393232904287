import React, { Component } from "react";
import { connect } from "react-redux";
import ChipsArray from "./ChipsArray";
import { withRouter } from "react-router-dom";

import lxwConstants from "../config/lexworkplace-constants";
const availableDeveloperOverrides = lxwConstants.availableDeveloperOverrides;

/*
	Displays what values are currently overridden compared
	to the defaults that are configured in the frontend.config.json
	and backend.config.json

	Currently these values must be set manually via the web console
*/

class DeveloperOverrides extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<ChipsArray
					style={{ justifyContent: "right" }}
					chips={this.props.enabledDeveloperOverrides.map(
						(
							{ shortLabel, value, fullValueDescription },
							index
						) => ({
							label: shortLabel + " : " + value,
							key: index,
							style: {
								backgroundColor: "var(--lw-gold)",
								padding: 4,
							},
							title: `Developer override currently set.  Overriding ${shortLabel} to use ${fullValueDescription}`,

							handleClick: () => {
								const { history } = this.props;
								history.push("/settings/developer");
							},
						})
					)}
				/>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		enabledDeveloperOverrides: Object.keys(state.developerOverrides)
			.filter((key) => {
				return state.developerOverrides[key] !== "OVERRIDE_DISABLED";
			})
			.map((enabledKey) => {
				const overrideValue = state.developerOverrides[enabledKey];

				const availableOverride =
					availableDeveloperOverrides[enabledKey];

				const overrideHasAllowedOptions = availableOverride?.options;

				const shortLabel = availableOverride
					? availableOverride.info.shortLabel
					: enabledKey;

				let fullValueDescription = overrideValue;

				if (overrideHasAllowedOptions) {
					fullValueDescription = availableOverride
						? availableOverride.options[overrideValue].display
						: overrideValue;
				}

				return {
					value: overrideValue,
					shortLabel,
					fullValueDescription,
				};
			}),
	};
};
export default connect(mapStateToProps, {})(withRouter(DeveloperOverrides));
