import React, { Component } from "react";
import { connect } from "react-redux";
import { setSubheaderText } from "../actions/navigateActions";
import { setDeveloperOverride } from "../actions/developerOverrideActions";

import { LabeledDropDownSelect } from "./DropDownSelect";

import lxwConstants from "../config/lexworkplace-constants";
const availableDeveloperOverrides = lxwConstants.availableDeveloperOverrides;

const bulkDownloadOverrideOptions = Object.keys(
	availableDeveloperOverrides.bulkDownloadServerAuthority.options
).map((key) => {
	return {
		value: key,
		label: availableDeveloperOverrides.bulkDownloadServerAuthority.options[
			key
		].display,
	};
});

class DeveloperOverridesSettings extends Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}
	getDefaultDropdownValue() {
		const matchingOption = bulkDownloadOverrideOptions.find(
			(option) =>
				option.value ===
				this.props.developerOverrides.bulkDownloadServerAuthority
		);
		if (matchingOption) {
			return matchingOption;
		} else {
			return [];
		}
	}

	componentDidMount() {
		this.props.setSubheaderText("Developer Settings");
	}
	handleChange(key, dropdownSelection) {
		if (dropdownSelection.value) {
			this.props.setDeveloperOverride(key, dropdownSelection.value);
		} else if (dropdownSelection.length === 0) {
			this.props.setDeveloperOverride(key, "OVERRIDE_DISABLED");
		}
	}

	render() {
		return (
			<div style={{ paddingLeft: 24 }}>
				<h3>Overrides</h3>
				<div
					style={{
						width: 600,
						display: "flex",
						flexDirection: "column",
						gap: 36,
					}}
				>
					<div>
						<LabeledDropDownSelect
							isClearable
							placeholder="Disabled (LxW API Server will use value from backend.config.json)"
							label="Override Bulk Download Server"
							options={bulkDownloadOverrideOptions}
							defaultValue={this.getDefaultDropdownValue()}
							name="bulkDownloadServerAuthority"
							handler={this.handleChange}
						/>
					</div>
					<div>
						{this.props.developerOverrides.apiName !==
						"OVERRIDE_DISABLED" ? (
							<div>
								The LexWorkplace Frontend is currently
								configured to use an API other than the default
								for this environment:
								{`"${this.props.developerOverrides.apiName}"`}.
								The default API for each environment is
								configured in the frontend config files:{" "}
								<pre>{`config/<environment_name>.frontend.config.json`}</pre>{" "}
								Run{" "}
								<pre>{`npm run setConfig <environment_name> <environment_name>`}</pre>{" "}
								to set the frontend to use any of the allowed
								APIs
							</div>
						) : (
							<div>
								The LexWorkplace Frontend is configured to use
								its normal API, set in{" "}
								<pre>{`config/<environment_name>.frontend.config.json`}</pre>
							</div>
						)}
					</div>
					<div>
						{this.props.developerOverrides.gitBranch !==
						"OVERRIDE_DISABLED" ? (
							<div>
								This hosted environment has been built from
								branch{" "}
								{`"${this.props.developerOverrides.gitBranch}"`}
							</div>
						) : (
							<div>
								If this is a hosted environment, it has been
								built from its usual branch
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	developerOverrides: state.developerOverrides,
});
export default connect(mapStateToProps, {
	setDeveloperOverride,
	setSubheaderText,
})(DeveloperOverridesSettings);
