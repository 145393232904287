import { logOut } from "./userAuthActions";

import {
	OPEN_PERSISTENT_ERROR_SNACKBAR,
	FOLDER_NAME_CONFLICT,
	BULK_DOWNLOAD_FAILURE,
	PASTE_FAILURE,
	UPLOAD_NEW_VERSION_FAILURE,
} from "./types";

import store from "../store";

const defaultErrorMessage = "LexWorkplace encountered an error";

export function handleLexworkplaceAPIError(
	error,
	errorData,
	errorHandlingArgs
) {
	console.error("A LexWorkplace API error was encountered", {
		error,
		errorData,
		errorHandlingArgs,
	});
	const { code, userMessage, description } = error;
	if (code >= 3000 && code <= 3100) {
		//unconnectable database, invalid config, etc.  API unusable
		showError(userMessage);
		redirectToHomepage();
	}

	if (code >= 6000 && code <= 6005) {
		insufficientMatterPermissionsError(error, errorHandlingArgs);
	} else if (code === 6060 || code === 6061 || code === 6080) {
		showError(userMessage);
	} else if (code === 6200) {
		store.dispatch({ type: FOLDER_NAME_CONFLICT, payload: errorData });
	} else if (code === 6300) {
		store.dispatch({
			type: BULK_DOWNLOAD_FAILURE,
			payload: { message: "error in export matter" },
		});
	} else if (code >= 6401 && code <= 6403) {
		store.dispatch({
			type: PASTE_FAILURE,
			payload: { message: description },
		});
	} else if (code >= 6600 && code <= 6601) {
		showError(description + ": " + JSON.stringify(errorData));
	} else if (code >= 5001 && code <= 5005) {
		logOut()(store.dispatch);

		//logOut clears the entire redux state, including the global snackbar visibility
		//using setTimeout to ensure snackbar error message shows
		setTimeout(() => showError(userMessage), 50);
	} else if (code === 7003) {
		showError("Share email could not be resent");
	} else if (code === 6006 || code === 6007) {
		insufficientFeatureCodeErrorMessage(
			error.description,
			errorHandlingArgs
		);
	} else if (code === 8500 || code === 8501) {
		store.dispatch({
			type: UPLOAD_NEW_VERSION_FAILURE,
			payload: {
				message: `New version could not be uploaded because ${description}`,
			},
		});
	} else if (code >= 8600 && code <= 8603) {
		showError(description);
	}
}

function insufficientMatterPermissionsError(error, errorHandlingArgs) {
	var dontRedirectToHomepage = false;

	if (errorHandlingArgs) {
		dontRedirectToHomepage = errorHandlingArgs.dontRedirectToHomepage;
	}

	const matterPermissionErrorMessage =
		"You do not have sufficient permissions to access this matter.\nPlease contact your firm administrator for more information";
	showError(matterPermissionErrorMessage);

	if (!dontRedirectToHomepage) {
		redirectToHomepage();
	}
}

function insufficientFeatureCodeErrorMessage(error, errorHandlingArgs) {
	var dontRedirectToHomepage = false;

	if (errorHandlingArgs) {
		dontRedirectToHomepage = errorHandlingArgs.dontRedirectToHomepage;
	}

	showError(error);

	if (!dontRedirectToHomepage) {
		redirectToHomepage();
	}
}

function showError(message = defaultErrorMessage) {
	store.dispatch({
		type: OPEN_PERSISTENT_ERROR_SNACKBAR,
		payload: {
			message,
		},
	});
}

function redirectToHomepage() {
	window.globalHistory.push("/");
}
