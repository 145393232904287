import React, { Component } from "react";
import { connect } from "react-redux";
import {
	fetchTenantDetail,
	rebuildSearchIndex,
	getS3BatchJobs,
	createAndRunBatchJobFromFailures,
	resendWelcomeEmail,
	setUserEnabledDisabled,
	createDatabaseUserRecord,
	deleteUser,
	setUserMFADisabled,
	unlockAllDocuments,
} from "../actions/adminActions";

import CustomizableTable from "./Tables/CustomizableTable";
import InteractiveTableCell from "./Tables/InteractiveTableCell";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { DropDownButtonWithRouter } from "./DropDownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextGrid from "./TextGrid";
import { setSubheaderText } from "../actions/navigateActions";

import MaterialTabs from "./MaterialTabs";

import { openGlobalDialog } from "../actions/globalEditDialogActions";

import AdminUsersForTenant from "./AdminUsersForTenant";
import AdminSearchIndexManagement from "./AdminSearchIndexManagement";
import { Flag } from "./Flag";

const extractCognitoAttribute = (attributes, attributeName) => {
	const att = attributes.filter((a) => a.Name === attributeName);
	return att.length ? att[0].Value : "";
};

const buildArrayWithConditions = (firstItem, conditionsArr) => {
	var out = [firstItem];
	conditionsArr.forEach(([conditionFn, valueIfTrue]) => {
		if (conditionFn()) {
			out.push(valueIfTrue);
		}
	});
	return out;
};

const tabMap = [
	{ label: "Users", url: "users" },
	{ label: "Search Index", url: "searchindex" },
	{ label: "Batch Jobs", url: "batchjobs" },
];

const subpageMap = {
	users: 0,
	searchindex: 1,
	batchjobs: 2,
};

const createTextForSubheader = (tenantName, tenantRegion) => {
	let htmlForSubheader = (
		<div style={{ display: "flex", columnGap: 5 }}>
			<Flag region={tenantRegion} />
			<div>{tenantName}</div>
		</div>
	);

	return htmlForSubheader;
};

class TenantDetail extends Component {
	constructor(props, context) {
		super(props, context);

		this.switchedTab = this.switchedTab.bind(this);
		this.loadProperTab = this.loadProperTab.bind(this);
		this.rebuildSearchIndexIntent =
			this.rebuildSearchIndexIntent.bind(this);
		this.refresh = this.refresh.bind(this);
		this.getSelectedTabContent = this.getSelectedTabContent.bind(this);
		this.state = {
			users: [],
			batchJobs: [],
			tenant: {},
		};

		this.batchJobsColumnConfig = [
			{ Header: "CreationTime", accessor: "CreationTime" },
			{ Header: "JobId", accessor: "JobId" },
			{ Header: "Description", accessor: "Description", width: 80 },
			{ Header: "Status", accessor: "Status" },
			{
				Header: "Successes",
				accessor: "ProgressSummarySuccess",
				Cell: (row) =>
					`${row.original.ProgressSummary.NumberOfTasksSucceeded} / ${row.original.ProgressSummary.TotalNumberOfTasks}`,
			},
			{
				Header: "Failures",
				accessor: "ProgressSummaryFailure",
				Cell: (row) => (
					<InteractiveTableCell
						menuItems={[
							{
								text: "re-try failures as new batch job",
								disabled: row.original.Status !== "Complete",
								onSelect: () =>
									createAndRunBatchJobFromFailures(
										this.props.match.params.tenantID,
										row.original.JobId
									),
							},
						]}
					>
						<span>{`${row.original.ProgressSummary.NumberOfTasksFailed} / ${row.original.ProgressSummary.TotalNumberOfTasks}`}</span>
					</InteractiveTableCell>
				),
			},
		];
	}
	refresh() {
		this.loadProperTab();
	}

	UNSAFE_componentWillMount() {
		this.loadProperTab();
	}

	loadProperTab() {
		const tenantID = this.props.match.params.tenantID;
		switch (this.props.match.params.tenantSubpage) {
			case "users":
				fetchTenantDetail(tenantID)
					.then((res) => {
						var cognitoUsers = {};
						var dbUsers = {};
						var lexworkplaceAdminUsers = {};
						var lockedDocuments = res.mysql.lockedDocuments;
						res.mysql.cognito.Users.forEach((u) => {
							/*
							The string value "false" is the only value that means an email address
							is unverified
						*/
							const emailVerifiedAttrValue =
								extractCognitoAttribute(
									u.Attributes,
									"email_verified"
								);

							const emailVerified =
								emailVerifiedAttrValue === "false"
									? false
									: true;

							const cognitoUserEnabled = u.Enabled;

							cognitoUsers[
								extractCognitoAttribute(u.Attributes, "sub")
							] = {
								UserStatus: u.UserStatus,
								UserCreateDate: u.UserCreateDate,
								emailAddress: extractCognitoAttribute(
									u.Attributes,
									"email"
								),
								name: extractCognitoAttribute(
									u.Attributes,
									"name"
								),
								username: u.Username,
								emailVerified,
								cognitoUserEnabled,
							};
						});
						res.mysql.lexworkplaceAdmins.Users.forEach((u) => {
							lexworkplaceAdminUsers[
								extractCognitoAttribute(u.Attributes, "sub")
							] = 1;
						});
						var usersToShow = res.mysql.users.map((u) => {
							var roles = [];
							if (lexworkplaceAdminUsers[u.sub]) {
								roles.push("LexWorkplace Admin");
							}
							if (u.type === "uptimeAdmin") {
								roles.push("Uptime Admin");
							}
							if (
								res.mysql.firmAdmins.filter(
									(firmAdmin) => firmAdmin.userSub === u.sub
								).length > 0
							) {
								roles.push("Firm Admin");
							}

							var userRow = { ...u, roles };
							dbUsers[u.sub] = { ...u, roles };
							if (cognitoUsers[u.sub]) {
								userRow.cognitoStatus =
									cognitoUsers[u.sub].UserStatus;
								userRow.cognitoCreatedDate =
									cognitoUsers[u.sub].UserCreateDate;
								userRow.cognitoEmailAddress =
									cognitoUsers[u.sub].emailAddress;
								userRow.cognitoUsername =
									cognitoUsers[u.sub].username;
								userRow.emailVerified =
									cognitoUsers[u.sub].emailVerified;
								userRow.cognitoUserEnabled =
									cognitoUsers[u.sub].cognitoUserEnabled;
								userRow.cognitoDisplayName =
									cognitoUsers[u.sub].name;
							} else {
								userRow.cognitoUserMissing = true;
							}

							userRow.lockedDocuments = lockedDocuments[u.sub]
								? lockedDocuments[u.sub].numLocked
								: 0;
							return userRow;
						});
						res.mysql.cognito.Users.forEach((u) => {
							const sub = extractCognitoAttribute(
								u.Attributes,
								"sub"
							);
							if (!dbUsers[sub]) {
								usersToShow.push({
									cognitoUsername: u.Username,
									sub,
									cognitoEmailAddress:
										extractCognitoAttribute(
											u.Attributes,
											"email"
										),
									cognitoDisplayName: extractCognitoAttribute(
										u.Attributes,
										"name"
									),
									cognitoUserEnabled: u.Enabled,
									cognitoStatus: u.UserStatus,
									cognitoCreatedDate: u.UserCreateDate,
									dbUserMissing: true,
									roles: lexworkplaceAdminUsers[sub]
										? ["LexWorkplace Admin"]
										: [],
								});
							}
						});

						usersToShow = usersToShow.map((user) => {
							const missingOneOrTheOther =
								user.cognitoUserMissing || user.dbUserMissing;

							const username = buildArrayWithConditions(
								user.cognitoUsername,
								[
									[
										() =>
											!missingOneOrTheOther &&
											user.username !==
												user.cognitoUsername,
										"UsernameMismatch",
									],
								]
							);

							const displayName = buildArrayWithConditions(
								user.displayName,
								[
									[
										() =>
											!missingOneOrTheOther &&
											user.displayName !==
												user.cognitoDisplayName,
										"DisplayNameMismatch",
									],
								]
							);

							const emailAddress = buildArrayWithConditions(
								user.cognitoEmailAddress,
								[
									[
										() =>
											!missingOneOrTheOther &&
											user.emailAddress !==
												user.cognitoEmailAddress,
										"EmailMismatch",
									],
									[
										() => user.emailVerified === false,
										"Unverified",
									],
								]
							);

							const status = (user.status =
								buildArrayWithConditions(
									user.cognitoUserMissing
										? ""
										: user.cognitoUserEnabled
											? "Active"
											: "Disabled",

									[
										[
											() => user.cognitoUserMissing,
											"No Cognito User",
										],
										[
											() => user.dbUserMissing,
											"No DB User",
										],
										[
											() =>
												user.cognitoStatus ===
												"FORCE_CHANGE_PASSWORD",
											"Has Not Logged In",
										],
									]
								));
							return {
								...user,
								username,
								displayName,
								emailAddress,
								status,
							};
						});

						this.setState({
							users: usersToShow,
							tenant: res.mysql.tenant,
							title: `Users for "${res.mysql.tenant.name}", tenantID:${res.mysql.tenant.id}`,
						});
						this.props.setSubheaderText(
							createTextForSubheader(
								res.mysql.tenant.name,
								res.mysql.tenantRegion
							)
						);
					})
					.catch((err) => console.error(err));
				break;
			case "batchjobs":
				this.props.getS3BatchJobs(tenantID).then((res) => {
					this.setState({
						batchJobs: res.jobs.Jobs,
						tenant: res.tenant,
						title: `Batch Jobs for "${res.tenant.name}", tenantID:${res.tenant.id}`,
					});
					console.log("res.tenant", res);

					this.props.setSubheaderText(
						createTextForSubheader(
							res.tenant.name,
							res.tenantRegion
						)
					);
				});
				break;

			case "searchindex":
				this.props.getS3BatchJobs(tenantID).then((res) => {
					this.setState({
						batchJobs: res.jobs.Jobs.filter(
							(job) =>
								job.Description.indexOf(
									`t/${tenantID}/reindex`
								) === 0
						),
						tenant: res.tenant,
						title: `Batch Jobs for "${res.tenant.name}", tenantID:${res.tenant.id}`,
					});
					this.props.setSubheaderText(
						createTextForSubheader(
							res.tenant.name,
							res.tenantRegion
						)
					);
				});
				break;
			default:
				console.log("lol");
		}
	}
	rebuildSearchIndexIntent() {
		this.props.openGlobalDialog(
			{
				label: "Delete and Rebuild Search Index?",
				mainText: `This will complely delete and rebuild the search index for firm:"${this.state.tenant.name}", tenantID:${this.state.tenant.id}.  To proceed, please type "rebuild" in the text field.`,
				value: "",
				submitButtonLabel: "Rebuild",
			},
			() => {
				this.props
					.rebuildSearchIndex(this.props.match.params.tenantID)
					.then((res) => console.log(res))
					.catch((err) => console.log(err));
			},
			(state) => {
				return {
					canSubmit: state.value === "rebuild",
					erroMessage: "",
				};
			}
		);
	}
	deleteUserIntent(username, sub, tenantID) {
		this.props.openGlobalDialog(
			{
				label: "Delete this user?",
				mainText: `This will complely delete both the Cognito and database user record for:"${username}".  If this is a testing/QA/internal user account, they will no longer be able to log in to any other lexworkplace environments (dev/newfeature/staging).  Please proceed with caution.  To delete the user, type "delete" in the text field.`,
				value: "",
				submitButtonLabel: "Delete",
			},
			() => {
				this.props.deleteUser(username, sub, tenantID, this.refresh);
			},
			(state) => {
				return {
					canSubmit: state.value === "delete",
					erroMessage: "",
				};
			}
		);
	}
	switchedTab(value) {
		const newURL = `/admin/tenant/${this.props.match.params.tenantID}/${tabMap[value].url}`;
		//const newURL =
		//"/firmDocs/" + this.state.lastLocation[tabMap[value].url];
		this.props.history.push(newURL);
	}
	componentDidUpdate(prevProps) {
		if (
			prevProps.match.params.tenantSubpage !==
				this.props.match.params.tenantSubpage ||
			prevProps.match.params.tenantID !== this.props.match.params.tenantID
		) {
			this.loadProperTab();
		}
	}

	getSelectedTabContent() {
		const subpageName = this.props.match.params.tenantSubpage;
		if (subpageName === "users") {
			return (
				<AdminUsersForTenant
					title={this.state.title}
					tenantID={this.props.match.params.tenantID}
					loading={this.props.loading}
					users={this.state.users}
					refresh={this.refresh}
				/>
			);
		} else if (subpageName === "batchjobs") {
			return (
				<CustomizableTable
					actionButtons={
						<div
							style={{
								marginLeft: "auto",
								marginTop: 0,
							}}
							className="buttonGroup"
						>
							<ButtonWithIcon
								handler={this.refresh}
								buttonText="Refresh"
								iconName="sync"
							/>
							<DropDownButtonWithRouter
								title="Actions"
								iconName="gavel"
								pullRight
								menuItems={[
									{
										text: "Edit Firm Name",
										href: `/admin/tenant/edit/${this.props.match.params.tenantID}`,
									},
									{
										text: "Re-build Search Index",
										onSelect: this.rebuildSearchIndexIntent,
									},
								]}
							/>
						</div>
					}
					leftSideFilterSpace={
						<h4 style={{ paddingLeft: 12 }}>
							<FontAwesomeIcon
								icon="search"
								size="1x"
								style={{ marginRight: 10 }}
							/>
							{this.state.title}
						</h4>
					}
					className="fullWidth"
					loading={this.props.loading}
					data={this.state.batchJobs}
					columns={this.batchJobsColumnConfig}
					noResultsText={"No Batch jobs to show"}
					autoResize
					tableUniqueID="/admin/batchJobsForFirm"
				/>
			);
		} else if (subpageName == "searchindex") {
			return (
				<AdminSearchIndexManagement
					tenantID={this.state.tenant.id}
					tenantName={this.state.tenant.name}
				/>
			);
		}
	}

	render() {
		return (
			<div className="fullHeightFlex noDefaultMargin">
				<div
					className="clientInfoHeader"
					style={{
						position: "relative",
						padding: 16,
					}}
				>
					<TextGrid
						numColumns={3}
						columns={[
							["TenantID", this.state.tenant.id],
							[
								"Internal Customer ID",

								this.state.tenant.customerID,
							],
							[
								"Username Prefix",
								this.state.tenant.usernamePrefix,
							],
						]}
					/>

					<div style={{ position: "absolute", top: 12, right: 12 }}>
						{}
					</div>
				</div>
				<MaterialTabs
					activeTab={
						subpageMap[this.props.match.params.tenantSubpage]
					}
					tabs={tabMap}
					switchedTab={this.switchedTab}
				/>
				{this.getSelectedTabContent()}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.request.loading,
});
export default connect(mapStateToProps, {
	fetchTenantDetail,
	setSubheaderText,
	getS3BatchJobs,
	createAndRunBatchJobFromFailures,
	rebuildSearchIndex,
	resendWelcomeEmail,
	setUserEnabledDisabled,
	createDatabaseUserRecord,
	openGlobalDialog,
	deleteUser,
	setUserMFADisabled,
	unlockAllDocuments,
})(TenantDetail);
